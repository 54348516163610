/* eslint-disable react/jsx-props-no-spreading */
import { HStack, Tab, TabList, Tabs } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { matchPath, useLocation, Link } from 'react-router-dom';
import { AppTabProps } from '../types/appTabs';
import { toCamelCase } from '../utils/helper';

function AppTabs({ tabDetails, basePath, tabListProps, ...props }: AppTabProps) {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const { pathname } = useLocation();

  const tabDetailValues = Object.values(tabDetails);
  const tabs = tabDetailValues.map(({ label }) => label);

  useEffect(() => {
    // controlled tabIndex to have correct "selected tab" even on page reloads
    const foundIndex = tabDetailValues.findIndex(({ path }) => {
      const isMatch = matchPath(pathname, `/${basePath}/${path.trim()}`);
      return isMatch;
    });

    setCurrentTab(foundIndex);
  }, [basePath, pathname, tabDetailValues]);

  return (
    <Tabs bgColor="white" index={currentTab} mb={0} {...props}>
      <TabList
        as={HStack}
        borderBottomColor="border_color"
        borderBottomWidth="1px"
        pl={6}
        px={6}
        spacing={5}
        {...tabListProps}
      >
        {tabs.map((label, index) => (
          <Link key={label} to={`/${basePath}/${tabDetailValues[index].path}`}>
            <Tab
              borderBottomColor="transparent"
              borderBottomWidth="2px"
              color="tab_item_base_color"
              data-cy={toCamelCase(label)}
              fontSize="md"
              key={label}
              lineHeight={5}
              mb="-px"
              pb="10px"
              pt={3}
              px={0}
              _selected={{
                color: 'tab_item_selected_color',
                borderColor: 'tab_item_selected_border_color',
              }}
              sx={{
                ':hover': {
                  color: 'tab_item_selected_color',
                },
                ':hover[aria-selected=true]': {
                  color: 'tab_item_selected_color',
                },
              }}
            >
              {label}
            </Tab>
          </Link>
        ))}
      </TabList>
    </Tabs>
  );
}

export default AppTabs;
