import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { Vendors } from 'routing-details';
import theme from '../theme';
import { useConfig } from './configContext';

function ThemeProvider({ children }: { children: React.ReactNode }) {
  const config = useConfig();
  const themeValue = config.theme || Vendors.Macrometa;
  return (
    <ChakraProvider theme={theme[themeValue]} resetCSS>
      {children}
    </ChakraProvider>
  );
}

export default ThemeProvider;
