import React from 'react';
import ErrorView from './ErrorBoundaryView';

type Props = {
  children: React.ReactNode;
};

type State = {
  error: boolean;
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: false };
  }

  componentDidCatch() {
    this.setState({
      error: true,
    });
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;
    if (error) {
      return <ErrorView reset={() => this.setState({ error: false })} />;
    }

    return children;
  }
}

export default ErrorBoundary;
