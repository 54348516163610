import { Box, Button, Icon, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useOutletContext, useParams } from 'react-router-dom';
import { commonDynamoAPI } from '../../../apis/collections';
import Lock from '../../../assets/icons/Lock';
import Minus from '../../../assets/icons/Minus';
import PlusCircle from '../../../assets/icons/PlusCircle';
import { DynamoHeaders, DynamoKeywords } from '../../../constants/collections';
import AppDeleteModal from '../../../elements/AppDeleteModal';
import DataTable from '../../../elements/table/DataTable';
import CreateDynamoIndexModal from '../../../modals/collections/CreateDynamoIndexModal';
import {
  CollectionParams,
  DynamoContextProps,
  DynamoIndex,
  DynamoTableRequest,
} from '../../../types/collections';
import { normaliseDynamoTableData } from '../../../utils/helper';
import { useConfig } from '../../../providers/configContext';

function getHeader(value: string) {
  return (
    <Text color="neutral.400" fontWeight="hairline">
      {value}
    </Text>
  );
}

function DynamoIndexes() {
  const { setShowHeaderItem, hasCollectionPermission, setRefetchCollectionMetrics } =
    useOutletContext<DynamoContextProps>();
  const [dynamoIndexTableData, setDynamoIndexTableData] = useState<DynamoIndex[]>([]);
  const params = useParams<keyof CollectionParams>() as CollectionParams;
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const selectedIndexToDeleteRef = useRef<DynamoIndex>();
  const [isCreateIndexModalVisible, setIsCreateIndexModalVisible] = useState(false);
  const { hasFabricPermission } = useConfig();

  useEffect(() => {
    setShowHeaderItem(false);
  }, [setShowHeaderItem]);

  const { mutate: dynamoIndexesMutation } = useMutation(
    () => commonDynamoAPI({ TableName: params.collection }, DynamoHeaders.DynamoDescribe),
    {
      onSuccess: (res) => {
        const tableData: DynamoIndex[] = normaliseDynamoTableData(res.data.Table);

        setDynamoIndexTableData(tableData);
      },
    },
  );

  useEffect(() => {
    dynamoIndexesMutation();
  }, [dynamoIndexesMutation]);

  const toggleDeleteModal = () => {
    setIsDeleteModalVisible((value) => !value);
  };

  const canDeleteIndex = useCallback(
    (item: DynamoIndex) =>
      item.type === DynamoKeywords.PrimaryKey ||
      item.type === DynamoKeywords.LocalSecondaryIndexes ? (
        // FIXME: Correct icon
        <Icon as={Lock} fill="neutral.400" fontSize="lg" height="5" ml="0.5" />
      ) : (
        <Button
          disabled={!hasCollectionPermission || !hasFabricPermission}
          height="4"
          minWidth="auto"
          textAlign="left"
          variant="unstyled"
          onClick={() => {
            selectedIndexToDeleteRef.current = item;
            toggleDeleteModal();
          }}
        >
          <Icon
            as={Minus}
            borderRadius="full"
            data-cy="deleteIndex"
            fill="no_document_danger_color"
            fontSize="lg"
          />
        </Button>
      ),
    [hasCollectionPermission, hasFabricPermission],
  );

  const toggleCreateIndexes = () => {
    setIsCreateIndexModalVisible((value) => !value);
  };

  const getAction = useCallback(
    (item: DynamoIndex) =>
      item.canCreateIndex ? (
        <Button
          disabled={!hasCollectionPermission || !hasFabricPermission}
          height="4"
          minWidth="auto"
          textAlign="left"
          variant="unstyled"
          onClick={toggleCreateIndexes}
        >
          <Icon
            as={PlusCircle}
            borderRadius="full"
            data-cy="addIndex"
            fill="positive"
            fontSize="lg"
          />
        </Button>
      ) : (
        canDeleteIndex(item)
      ),
    [canDeleteIndex, hasCollectionPermission, hasFabricPermission],
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Type',
        accessor: 'type',
        Cell: ({ row: { original } }: Record<string, any>) => getHeader(original.type),
      },
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row: { original } }: Record<string, any>) => getHeader(original.name),
      },
      {
        Header: 'Partition Key',
        accessor: 'partitionKey',
        Cell: ({ row: { original } }: Record<string, any>) => getHeader(original.partitionKey),
      },
      {
        Header: 'Sorting Key',
        accessor: 'sortingKey',
        Cell: ({ row: { original } }: Record<string, any>) => getHeader(original.sortingKey),
      },

      {
        Header: 'Action',
        accessor: 'actions',
        Cell: ({ row: { original } }: Record<string, any>) => getAction(original),
      },
    ],
    [getAction],
  );

  const emptyIndexRowWithAction: DynamoIndex = {
    canCreateIndex: true,
    name: '',
    partitionKey: '',
    sortingKey: '',
    type: '',
  };

  const { mutate: deleteMutation } = useMutation(
    ({ body }: { body: DynamoTableRequest }) => commonDynamoAPI(body, DynamoHeaders.UpdateTable),
    {
      onSuccess: () => {
        dynamoIndexesMutation();
      },
    },
  );

  const handleConfirmDelete = () => {
    if (selectedIndexToDeleteRef.current) {
      const body = {
        TableName: params.collection,
        GlobalSecondaryIndexUpdates: [
          {
            Delete: {
              IndexName: selectedIndexToDeleteRef.current?.name,
            },
          },
        ],
      };
      deleteMutation({ body });
    }
  };

  return (
    <Box>
      <DataTable columns={columns} data={dynamoIndexTableData.concat([emptyIndexRowWithAction])} />

      {isDeleteModalVisible && (
        <AppDeleteModal
          isOpen={isDeleteModalVisible}
          itemLabel="Delete"
          itemValue="Really delete Global Secondary Index ?"
          modalTitle="Delete Global Secondary Index?"
          onClose={toggleDeleteModal}
          onConfirmDelete={handleConfirmDelete}
        />
      )}
      {isCreateIndexModalVisible && (
        <CreateDynamoIndexModal
          isOpen={isCreateIndexModalVisible}
          refetch={dynamoIndexesMutation}
          setRefetchCollectionMetrics={setRefetchCollectionMetrics}
          tableName={params.collection}
          toggleModal={toggleCreateIndexes}
        />
      )}
    </Box>
  );
}

export default DynamoIndexes;
