import Cookies from 'js-cookie';
import { Fabrics } from '../constants/common';
import { Persist } from '../constants/persist';
import { SubDomain } from '../types/commonTypes';
import { GetSession, ISession, SetSession } from '../types/session';

// ----------------------------------------------------------------------------------------- //

export const getCookieValue = (key: string) => Cookies.get(key);

export const setCookieValue = (key: string, value: string) => {
  const isLocal = import.meta.env.DEV;
  Cookies.set(key, value, {
    secure: !isLocal,
    sameSite: 'strict',
    hostOnly: true,
  });
};

export const removeCookie = (key: string) => Cookies.remove(key);

export const setCookieValueToSubDomain = (key: string, value: string) => {
  const expires = new Date(Date.now() + 5 * 60 * 1000).toUTCString();

  window.document.cookie = `${key}=${value};domain=${SubDomain.Macrometa};path=/;expires=${expires};secure;SameSite=Strict`;
};

// ---- //

export const setSessionStorage = (keyName: string, value: string) => {
  sessionStorage.setItem(keyName, value);
};

export const removeSessionStorage = (keyName: string) => {
  sessionStorage.removeItem(keyName);
};

export const getSessionStorage = (keyName: string) => sessionStorage.getItem(keyName);

// ----------------------------------------------------------------------------------------- //

// FIXME: properly use secure cookies. Use "secure" & "domain"
export const getSession: GetSession = () => {
  const user: ISession = JSON.parse(getCookieValue(Persist.Session) ?? '{}');
  const { token, tenant, username } = user;
  return { token, tenant, username };
};

export const setSession: SetSession = (sessionDetails: ISession) => {
  setCookieValue(Persist.Session, JSON.stringify(sessionDetails));
};

export const setFabric = (fabric: string) => setCookieValue(Persist.Fabric, fabric);

export const getFabric = () => {
  const fabric = getCookieValue(Persist.Fabric);
  // FIXME: avoid passing default as much as possible
  return fabric || Fabrics.System;
};

export const getRedirectURL = () => {
  const redirectURL = getCookieValue(Persist.RedirectURL);
  return redirectURL || '';
};
