import { getSession } from '../utils/persist';

export default function RequireAuth({ children }: { children: JSX.Element }) {
  const { token, tenant, username } = getSession();
  if (!token || !tenant || !username) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    window.location.replace(window.location.origin);

    return null;
  }

  return children;
}
