import { createIcon } from '@chakra-ui/react';

const Stream = createIcon({
  displayName: 'StreamIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        clipRule="evenodd"
        d="M7.44218 4.84274C5.87379 4.5598 4.1647 4.76382 2.0912 6.04456C1.56258 6.37107 0.869371 6.20724 0.542862 5.67862C0.216354 5.15001 0.380191 4.4568 0.908804 4.13029C3.42905 2.5736 5.69823 2.24181 7.84162 2.62848C9.91231 3.00202 11.7939 4.03969 13.5359 5.01944C16.7571 6.83118 20.2034 6.22436 21.6089 4.40068C21.9882 3.90856 22.6946 3.81708 23.1867 4.19636C23.6789 4.57564 23.7703 5.28205 23.3911 5.77418C21.0778 8.77564 16.2673 9.13718 12.4329 6.98054C10.647 5.97611 9.08328 5.13878 7.44218 4.84274Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M7.44218 10.8427C5.87379 10.5598 4.1647 10.7638 2.0912 12.0446C1.56258 12.3711 0.869371 12.2072 0.542862 11.6786C0.216354 11.15 0.380191 10.4568 0.908804 10.1303C3.42905 8.5736 5.69823 8.24181 7.84162 8.62848C9.91231 9.00202 11.7939 10.0397 13.5359 11.0194C16.7571 12.8312 20.2034 12.2244 21.6089 10.4007C21.9882 9.90856 22.6946 9.81708 23.1867 10.1964C23.6789 10.5756 23.7703 11.2821 23.3911 11.7742C21.0778 14.7756 16.2673 15.1372 12.4329 12.9805C10.647 11.9761 9.08328 11.1388 7.44218 10.8427Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M7.44218 16.8427C5.87379 16.5598 4.1647 16.7638 2.0912 18.0446C1.56258 18.3711 0.869371 18.2072 0.542862 17.6786C0.216354 17.15 0.380191 16.4568 0.908804 16.1303C3.42905 14.5736 5.69823 14.2418 7.84162 14.6285C9.91231 15.002 11.7939 16.0397 13.5359 17.0194C16.7571 18.8312 20.2034 18.2244 21.6089 16.4007C21.9882 15.9086 22.6946 15.8171 23.1867 16.1964C23.6789 16.5756 23.7703 17.2821 23.3911 17.7742C21.0778 20.7756 16.2673 21.1372 12.4329 18.9805C10.647 17.9761 9.08328 17.1388 7.44218 16.8427Z"
        fillRule="evenodd"
      />
    </g>
  ),
});

export default Stream;
