import { createIcon } from '@chakra-ui/react';

const ChevronLeft = createIcon({
  displayName: 'ChevronLeftIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      clipRule="evenodd"
      d="M15.3477 4.21478C15.8603 4.72734 15.8603 5.55837 15.3477 6.07093L9.41866 12L15.3477 17.9291C15.8603 18.4416 15.8603 19.2727 15.3477 19.7852C14.8352 20.2978 14.0041 20.2978 13.4916 19.7852L6.63442 12.9281C6.12186 12.4155 6.12186 11.5845 6.63442 11.0719L13.4916 4.21478C14.0041 3.70221 14.8352 3.70221 15.3477 4.21478Z"
      fillRule="evenodd"
    />
  ),
});

export default ChevronLeft;
