import {
  Switch,
  Text,
  HStack,
  Tooltip,
  Button,
  Select,
  Box,
  Flex,
  Input,
  Icon,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  DynamoAttributeTypes,
  DynamoFilterAttribute,
  DynamoFilterType,
} from '../../constants/collections';
import tooltips from '../../constants/tooltips';
import { StringConstants } from '../../constants/userMessages';
import {
  CollectionParams,
  DynamoCollectionHeaderProps,
  DynamoFilterAttributes,
  DynamoFilterRequest,
  SecondaryIndex,
} from '../../types/collections';
import { useConfig } from '../../providers/configContext';
import { getDynamoFilterQueryKeys } from '../../utils/helper';

/* Icons */
import DownTriangleIcon from '../../assets/icons/DownTriangle';
import FilterIcon from '../../assets/icons/Filter';
import MinusIcon from '../../assets/icons/Minus';
import PlusCircleIcon from '../../assets/icons/PlusCircle';

function DynamoCollectionHeader({
  handleStreamStatus,
  streamStatus,
  primaryKeys,
  handleScan,
  descriptionData,
  hasCollectionPermission,
  filterAttributes,
  handleAttributes,
  filterType,
  handleFilterType,
  indexName,
  handleIndex,
  limit,
}: DynamoCollectionHeaderProps) {
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const params = useParams<keyof CollectionParams>() as CollectionParams;
  const emptyAttribute: DynamoFilterAttributes = {
    comparator: '=',
    keyName: '',
    keyType: DynamoAttributeTypes.STRING,
    value: '',
  };
  const [mandatoryAttributes, setMandatoryAttributes] = useState<string[]>([]);
  const { hasFabricPermission } = useConfig();

  const addFilters = (attributes: string[]) => {
    // adding filters if not already available
    const dynamicFilters: DynamoFilterAttributes[] = [];
    if (!filterAttributes.every((attribute) => attributes.includes(attribute.keyName)))
      attributes.forEach((attribute) => {
        if (filterAttributes.filter((item) => item.keyName === attribute).length === 0) {
          dynamicFilters.push({
            comparator: '=',
            keyName: attribute,
            keyType: DynamoAttributeTypes.STRING,
            value: '',
          });
        }
      });
    setMandatoryAttributes(attributes);
    handleAttributes(filterAttributes.concat(dynamicFilters));
  };

  const handleTableAttributes = (selectedIndexOrTable: string) => {
    if (selectedIndexOrTable === '') {
      addFilters(primaryKeys);
    } else if (
      selectedIndexOrTable &&
      descriptionData &&
      descriptionData.Table.GlobalSecondaryIndexes
    ) {
      const selectedIndex = descriptionData.Table.GlobalSecondaryIndexes.find(
        (item) => item.IndexName === selectedIndexOrTable,
      );
      if (selectedIndex && selectedIndex.KeySchema) {
        const selectedIndexAttributes = selectedIndex.KeySchema.map((item) => item.AttributeName);
        addFilters(selectedIndexAttributes);
      }
    } else {
      throw new Error(StringConstants.NO_DATA_FOUND);
    }
  };

  const handleAddFilterAttribute = () => {
    handleAttributes(filterAttributes.concat(emptyAttribute));
  };

  const handleRemoveFilterAttribute = (index: number) => {
    const newAttributes = [...filterAttributes];
    if (filterAttributes.length <= 1 && filterType === DynamoFilterType.SCAN) {
      newAttributes[index].keyName = '';
      handleAttributes([...filterAttributes]);
    } else {
      handleAttributes(filterAttributes.filter((_, i) => i !== index));
    }
  };

  const resetAttributes = () => {
    filterAttributes.forEach((item, index) => {
      if (item.keyName === '' || item.value === '') {
        filterAttributes.splice(index, 1);
      }
    });
    handleAttributes([...filterAttributes]);
  };

  const handleSearch = () => {
    const validAttributes = filterAttributes.filter((item) => item.keyName && item.value);
    const { FilterExpression, ExpressionAttributeNames, ExpressionAttributeValues } =
      getDynamoFilterQueryKeys(validAttributes);

    if (validAttributes.length === 0) {
      setIsFilterVisible(false);

      return;
    }

    const body: DynamoFilterRequest = {
      ExpressionAttributeNames,
      ExpressionAttributeValues,
      Limit: limit,
      TableName: params.collection,
    };
    if (filterType === DynamoFilterType.SCAN) {
      body.FilterExpression = FilterExpression;
    } else {
      body.KeyConditionExpression = FilterExpression;
    }
    if (descriptionData && descriptionData.Table.GlobalSecondaryIndexes && indexName) {
      body.IndexName = indexName;
    }

    handleScan(body, filterType, resetAttributes);
  };

  const handleOnChange = (
    value: string | DynamoAttributeTypes,
    index: number,
    key:
      | DynamoFilterAttribute.COMPARATOR
      | DynamoFilterAttribute.KEYNAME
      | DynamoFilterAttribute.KEYTYPE
      | DynamoFilterAttribute.VALUE,
  ) => {
    const newAttributes = [...filterAttributes];
    if (key === DynamoFilterAttribute.KEYTYPE) {
      newAttributes[index][key] = value as DynamoAttributeTypes;
    } else {
      newAttributes[index][key] = value;
    }
    handleAttributes([...filterAttributes]);
  };

  const handleTableChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    handleIndex(e.target.value);
    if (filterType === DynamoFilterType.QUERY) {
      handleTableAttributes(e.target.value);
    }
  };

  const getDisabledStatus = (attributeItem: DynamoFilterAttributes) => {
    if (filterType === DynamoFilterType.QUERY) {
      if (indexName && descriptionData.Table.GlobalSecondaryIndexes) {
        const selectedIndex = descriptionData.Table.GlobalSecondaryIndexes.find(
          (item) => item.IndexName === indexName,
        ) as SecondaryIndex;
        if (selectedIndex && selectedIndex.KeySchema.length) {
          return (
            selectedIndex && selectedIndex.KeySchema[0].AttributeName === attributeItem.keyName
          );
        }
        return false;
      }
      return descriptionData.Table.AttributeDefinitions[0].AttributeName === attributeItem.keyName;
    }
    return false;
  };

  return (
    <>
      <HStack borderBottomColor="border_color" borderBottomWidth="1px" px={6} py={3}>
        <Tooltip label={tooltips.FilterItems}>
          <Button
            data-cy="filter"
            h="8"
            iconSpacing="inherit"
            leftIcon={<FilterIcon />}
            mx="0.5"
            size="sm"
            variant={isFilterVisible ? 'solid' : 'outline'}
            w="8"
            sx={{
              fill: isFilterVisible ? 'white' : 'toolbar_icon_inactive',
              _hover: {
                bg: 'positive_hover',
                fill: 'white',
              },
              borderColor: 'neutral.200',
            }}
            onClick={() => setIsFilterVisible((value) => !value)}
          />
        </Tooltip>
        <Switch
          isChecked={streamStatus}
          isDisabled={!hasCollectionPermission || !hasFabricPermission || true}
          mr={3}
          size="md"
          onChange={handleStreamStatus}
        />
        <Text w="auto">
          {streamStatus ? StringConstants.DISABLE_STREAM : StringConstants.ENABLE_STREAM}
        </Text>
      </HStack>
      {isFilterVisible && (
        <Box borderBottomColor="border_color" borderBottomWidth="1px" px={6} py={3}>
          <Flex
            borderColor="border_color"
            borderRadius="md"
            borderWidth="thin"
            flexDirection="column"
            mb={2}
          >
            <Flex>
              <Flex borderRightColor="neutral.100" borderRightWidth="thin" p="2">
                <Select
                  borderColor="neutral.100"
                  borderRadius="base"
                  borderWidth="thin"
                  icon={<Icon as={DownTriangleIcon} fill="neutral.400" fontSize="xs" />}
                  value={filterType}
                  variant="neutral"
                  w="28"
                  onChange={(e) => {
                    const { value } = e.target;
                    handleFilterType(value as DynamoFilterType);
                    if (value === DynamoFilterType.QUERY) {
                      handleTableAttributes(indexName);
                    }
                  }}
                >
                  <option value={DynamoFilterType.SCAN}>Scan</option>
                  <option value={DynamoFilterType.QUERY}>Query</option>
                </Select>
              </Flex>
              <Select
                borderColor="neutral.100"
                borderRadius="base"
                borderWidth="thin"
                isDisabled={!(descriptionData && descriptionData.Table.GlobalSecondaryIndexes)}
                p="2"
                value={indexName || params.collection}
                variant="neutral"
                onChange={handleTableChange}
              >
                <option value="">[Table] {params.collection}</option>
                {descriptionData &&
                  descriptionData.Table.GlobalSecondaryIndexes &&
                  descriptionData.Table.GlobalSecondaryIndexes.map((item) => {
                    const attributes = item.KeySchema.map((k) => k.AttributeName);
                    return (
                      <option key={item.IndexName} value={item.IndexName}>
                        [index] {item.IndexName}: {attributes.join(', ')}
                      </option>
                    );
                  })}
              </Select>
            </Flex>
            <Flex borderTopColor="neutral.100" borderTopWidth="thin" flexDirection="column" p="2">
              {filterAttributes.map((item, index) => (
                <Flex data-cy={`attributeRow${index}`} key={`attribute${index.toString()}`}>
                  <Flex borderColor="neutral.100" borderRadius="base" borderWidth="thin" mb={2}>
                    <Flex borderRightColor="neutral.100" borderRightWidth="thin" p="2">
                      <Input
                        name={`${DynamoFilterAttribute.KEYNAME}${index}`}
                        placeholder="Key"
                        value={item.keyName}
                        w="52"
                        onChange={(e) =>
                          handleOnChange(e.target.value, index, DynamoFilterAttribute.KEYNAME)
                        }
                      />
                    </Flex>
                    <Flex borderRightColor="neutral.100" borderRightWidth="thin" p="2">
                      <Select
                        borderColor="neutral.100"
                        borderRadius="base"
                        borderWidth="thin"
                        data-cy="valueType"
                        icon={<Icon as={DownTriangleIcon} fill="neutral.400" fontSize="xs" />}
                        name={`${DynamoFilterAttribute.KEYTYPE}${index}`}
                        value={item.keyType}
                        variant="neutral"
                        onChange={(e) =>
                          handleOnChange(e.target.value, index, DynamoFilterAttribute.KEYTYPE)
                        }
                      >
                        <option value="S">String</option>
                        <option value="N">Number</option>
                      </Select>
                    </Flex>
                    <Flex borderRightColor="neutral.100" borderRightWidth="thin" p="2">
                      <Select
                        borderColor="neutral.100"
                        borderRadius="base"
                        borderWidth="thin"
                        data-cy="comparator"
                        disabled={getDisabledStatus(item)}
                        icon={<Icon as={DownTriangleIcon} fill="neutral.400" fontSize="xs" />}
                        name={`${DynamoFilterAttribute.COMPARATOR}${index}`}
                        value={item.comparator}
                        variant="neutral"
                        onChange={(e) =>
                          handleOnChange(e.target.value, index, DynamoFilterAttribute.COMPARATOR)
                        }
                      >
                        <option value="=">=</option>
                        <option value="<>">≠</option>
                        <option value=">=">&gt;=</option>
                        <option value="<=">&lt;=</option>
                        <option value=">">&gt;</option>
                        <option value="<">&lt;</option>
                      </Select>
                    </Flex>
                    <Flex borderRightColor="neutral.100" borderRightWidth="thin" p="2">
                      <Input
                        name={`${DynamoFilterAttribute.VALUE}${index}`}
                        placeholder="Value"
                        value={item.value}
                        w="52"
                        onChange={(e) =>
                          handleOnChange(e.target.value, index, DynamoFilterAttribute.VALUE)
                        }
                      />
                    </Flex>
                    <Flex align="center" justify="center" minW="12" p="2">
                      {((filterType === DynamoFilterType.QUERY &&
                        !mandatoryAttributes.includes(item.keyName)) ||
                        filterType === DynamoFilterType.SCAN) && (
                        <Icon
                          as={MinusIcon}
                          fill="red.400"
                          onClick={() => handleRemoveFilterAttribute(index)}
                        />
                      )}
                    </Flex>
                  </Flex>
                  {index === filterAttributes.length - 1 && (
                    <Flex align="center" p="4">
                      <Icon
                        as={PlusCircleIcon}
                        data-cy="addAttribute"
                        fill="positive"
                        onClick={handleAddFilterAttribute}
                      />
                    </Flex>
                  )}
                </Flex>
              ))}
            </Flex>
          </Flex>
          <Button data-cy="search" onClick={handleSearch}>
            Search
          </Button>
        </Box>
      )}
    </>
  );
}
export default DynamoCollectionHeader;
