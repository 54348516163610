import { createIcon } from '@chakra-ui/react';

const Globe = createIcon({
  displayName: 'Globe',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        clipRule="evenodd"
        d="M12 2.5C6.75329 2.5 2.5 6.75329 2.5 12C2.5 17.2467 6.75329 21.5 12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5ZM0.5 12C0.5 5.64873 5.64873 0.5 12 0.5C18.3513 0.5 23.5 5.64873 23.5 12C23.5 18.3513 18.3513 23.5 12 23.5C5.64873 23.5 0.5 18.3513 0.5 12Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M0.5 12C0.5 11.4477 0.947715 11 1.5 11H22.5C23.0523 11 23.5 11.4477 23.5 12C23.5 12.5523 23.0523 13 22.5 13H1.5C0.947715 13 0.5 12.5523 0.5 12Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12 0.5C12.301 0.5 12.586 0.635567 12.7758 0.869085C15.3355 4.01672 16.8278 7.8968 17.0371 11.9484L16.0385 12L17.0371 12.0516C16.8278 16.1032 15.3355 19.9833 12.7758 23.1309C12.586 23.3644 12.301 23.5 12 23.5C11.699 23.5 11.414 23.3644 11.2241 23.1309C8.66451 19.9833 7.17218 16.1032 6.96287 12.0516C6.96109 12.0172 6.96109 11.9828 6.96287 11.9484C7.17218 7.8968 8.66451 4.01672 11.2241 0.869085C11.414 0.635567 11.699 0.5 12 0.5ZM15.037 12C14.8632 8.82146 13.8075 5.76274 12 3.16204C10.1925 5.76274 9.13676 8.82146 8.96295 12C9.13676 15.1785 10.1925 18.2373 12 20.838C13.8075 18.2373 14.8632 15.1785 15.037 12Z"
        fillRule="evenodd"
      />
    </g>
  ),
});

export default Globe;
