import { useState } from 'react';
import { Box, Drawer, DrawerBody, DrawerContent, useBreakpointValue } from '@chakra-ui/react';
import { SidebarProps } from '../../types/sidebar';
import SidebarContent from './SideBarContent';

const smVariant = { navigation: 'drawer' };
const mdVariant = { navigation: 'sidebar' };

function Sidebar({ isOpen }: SidebarProps) {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const variants = useBreakpointValue({ base: smVariant, md: mdVariant });
  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);

  return variants?.navigation === 'sidebar' ? (
    <Box h="100%" position="fixed" w={{ base: 'full', md: 60 }}>
      <SidebarContent onClose={toggleSidebar} />
    </Box>
  ) : (
    // FIXME: implement drawer as per figma for mobile
    <Drawer isOpen={isOpen} placement="left" size="2xs" onClose={toggleSidebar}>
      <DrawerContent>
        <DrawerBody p={0}>
          <SidebarContent onClose={toggleSidebar} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default Sidebar;
