import { createIcon } from '@chakra-ui/react';

const Studio = createIcon({
  displayName: 'StudioIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        clipRule="evenodd"
        d="M4.23082 7.34615C4.23082 6.79386 4.67853 6.34615 5.23082 6.34615H8.61543C9.16772 6.34615 9.61543 6.79386 9.61543 7.34615C9.61543 7.89843 9.16772 8.34615 8.61543 8.34615H5.23082C4.67853 8.34615 4.23082 7.89843 4.23082 7.34615Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M4.23082 12.4231C4.23082 11.8708 4.67853 11.4231 5.23082 11.4231H6.92313C7.47541 11.4231 7.92313 11.8708 7.92313 12.4231C7.92313 12.9754 7.47541 13.4231 6.92313 13.4231H5.23082C4.67853 13.4231 4.23082 12.9754 4.23082 12.4231Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M22.1538 1.26923H1.84615C0.826551 1.26923 0 2.09578 0 3.11538V16.6538C0 17.6734 0.826551 18.5 1.84615 18.5H8.83066L7.93835 20.7308H6.5C5.94772 20.7308 5.5 21.1785 5.5 21.7308C5.5 22.283 5.94772 22.7308 6.5 22.7308H8.59167C8.6073 22.7311 8.62291 22.7311 8.63847 22.7308H15.3615L15.385 22.731L15.4083 22.7308H17.5C18.0523 22.7308 18.5 22.283 18.5 21.7308C18.5 21.1785 18.0523 20.7308 17.5 20.7308H16.0616L15.1693 18.5H22.1538C23.1734 18.5 24 17.6734 24 16.6538V3.11538C24 2.09578 23.1734 1.26923 22.1538 1.26923ZM2 16.5V3.26923H11.8462V10.7157C11.846 10.7261 11.846 10.7365 11.8462 10.747V16.5H10.3308C10.3156 16.4996 10.3004 16.4996 10.2852 16.5H2ZM13.8462 11.2016V16.5H22V12.8709L18.5584 9.79801C18.133 9.45113 17.6007 9.26152 17.0515 9.26152C16.4965 9.26152 15.9589 9.4551 15.5313 9.80891L13.8462 11.2016ZM22 10.1897V3.26923H13.8462V8.60693L14.2563 8.26801C15.0424 7.6177 16.0312 7.26152 17.0515 7.26152C18.0719 7.26152 19.0605 7.61746 19.8467 8.26801C19.8563 8.276 19.8658 8.28417 19.8752 8.29252L22 10.1897ZM10.9847 18.5H13.0152L13.9076 20.7308H10.0924L10.9847 18.5Z"
        fillRule="evenodd"
      />
    </g>
  ),
});

export default Studio;
