// eslint-disable-next-line import/prefer-default-export
export const getBaseURL = (): string => {
  const BACKEND_PREFIX = 'api-';
  const PROTOCOL = 'https';

  // local is either when vite is running in dev mode
  // or when we want to test with docker build where "VITE_IS_DOCKER_DEV" is true
  const isLocal =
    import.meta.env.DEV === true ||
    import.meta.env.VITE_IS_DOCKER_DEV === true ||
    import.meta.env.VITE_IS_DOCKER_DEV === 'true';

  let fedUrl;
  if (isLocal) {
    const devUrl = import.meta.env.VITE_DEV_URL;
    if (typeof devUrl !== 'string') {
      throw new Error("You must provide a url to connect to backend. Refer to '.env-example'");
    }
    fedUrl = new URL(devUrl);
  } else {
    fedUrl = window.location;
  }
  const { host } = fedUrl;
  const baseURL = `${PROTOCOL}://${BACKEND_PREFIX}${host}`;
  return baseURL;
};
