import { ApiCallMethods } from '../constants/api';
import {
  CreateUser,
  EditPasswd,
  HubSpotTaskPayLoad,
  InviteEmail,
  UpdateEmail,
  UpdateUserDetails,
  User,
} from '../types/accounts';
import { ApiResponse } from '../types/commonTypes';
import { callApi } from '../utils/api';
import { getBaseURL } from '../utils/federation';
import { getSession } from '../utils/persist';

export const getAccountUsersData = (): Promise<ApiResponse<User>> => {
  const url = `${getBaseURL()}/_api/user`;
  return callApi(ApiCallMethods.Get, url);
};

export const createUser = (user: CreateUser) => {
  const url = `${getBaseURL()}/_api/user`;
  return callApi(ApiCallMethods.Post, url, user);
};

export const userDetails = (user: string) => {
  const url = `${getBaseURL()}/_api/user/${user}`;
  return callApi(ApiCallMethods.Get, url);
};

export const editPasswd = (user: string, passwd: EditPasswd) => {
  const url = `${getBaseURL()}/_api/user/${user}`;
  return callApi(ApiCallMethods.Patch, url, passwd);
};

export const deleteUser = (user: User) => {
  const url = `${getBaseURL()}/_tenant/${user.tenant}/_api/user/${user.user}`;
  return callApi(ApiCallMethods.Delete, url);
};

export const updateUserDetails = (user: User, updatedUserDetails: UpdateUserDetails) => {
  const url = `${getBaseURL()}/_tenant/${user.tenant}/_api/user/${user.user}`;
  return callApi(ApiCallMethods.Patch, url, updatedUserDetails);
};

export const getTenantUserDetails = () => {
  const url = `${getBaseURL()}/_api/tenant/${getSession().tenant}`;
  return callApi(ApiCallMethods.Get, url);
};

export const getNonRootAccountUsersData = (tenant: string, username: string) => {
  const url = `${getBaseURL()}/_api/user/${tenant}.${username}`;
  return callApi(ApiCallMethods.Get, url);
};

export const verifyNewEmail = (authURL: string, payload: UpdateEmail) => {
  const url = `${authURL}/_api/auth/update-tenant-email`;
  return callApi(ApiCallMethods.Post, url, payload);
};

export const cancelVerification = (authURL: string, payload: UpdateEmail) => {
  const url = `${authURL}/_api/auth/update-tenant-email/cancel-email-update`;
  return callApi(ApiCallMethods.Post, url, payload);
};

export const getInvitedUsers = (authURL: string, email?: string) => {
  let url = `${authURL}/_api/auth/invite-user`;
  if (email) {
    url = `${url}?email=${email}`;
  }
  return callApi(ApiCallMethods.Get, url, undefined, {
    withCredentials: true,
  });
};

export const resendInvitationLink = (authURL: string, invitationId: string) => {
  const url = `${authURL}/_api/auth/invite-user/${invitationId}`;
  return callApi(ApiCallMethods.Put, url, undefined, {
    withCredentials: true,
  });
};

export const deleteInvitation = (authURL: string, email: string) => {
  const url = `${authURL}/_api/auth/invite-user/${email}`;
  return callApi(ApiCallMethods.Delete, url, undefined, {
    withCredentials: true,
  });
};

export const sendInvitationMail = (authURL: string, invitationInfo: InviteEmail[]) => {
  const url = `${authURL}/_api/auth/invite-user`;
  return callApi(ApiCallMethods.Post, url, invitationInfo, {
    withCredentials: true,
  });
};

export const createHubSpotTask = (hubSpotTaskPayLoad: HubSpotTaskPayLoad) => {
  const url = '/create-hubspot-task';
  return callApi(ApiCallMethods.Post, url, hubSpotTaskPayLoad);
};
