import { createIcon } from '@chakra-ui/react';

const Activity = createIcon({
  displayName: 'ActivityIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        clipRule="evenodd"
        d="M22.2761 8.30705C22.8968 8.30705 23.4 8.81026 23.4 9.431V22.2761C23.4 22.8968 22.8968 23.4 22.2761 23.4C21.6553 23.4 21.1521 22.8968 21.1521 22.2761V9.431C21.1521 8.81026 21.6553 8.30705 22.2761 8.30705Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12 0.6C12.6207 0.6 13.1239 1.10321 13.1239 1.72394V22.2761C13.1239 22.8968 12.6207 23.4 12 23.4C11.3793 23.4 10.8761 22.8968 10.8761 22.2761V1.72394C10.8761 1.10321 11.3793 0.6 12 0.6Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M6.86197 5.73803C7.48271 5.73803 7.98592 6.24124 7.98592 6.86197L7.98592 22.2761C7.98592 22.8968 7.48271 23.4 6.86197 23.4C6.24124 23.4 5.73803 22.8968 5.73803 22.2761L5.73803 6.86197C5.73803 6.24124 6.24124 5.73803 6.86197 5.73803Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M17.138 16.0141C17.7588 16.0141 18.262 16.5173 18.262 17.138V22.2761C18.262 22.8968 17.7588 23.4 17.138 23.4C16.5173 23.4 16.0141 22.8968 16.0141 22.2761V17.138C16.0141 16.5173 16.5173 16.0141 17.138 16.0141Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M1.72394 13.4451C2.34468 13.4451 2.84789 13.9483 2.84789 14.569V22.276C2.84789 22.8968 2.34468 23.4 1.72394 23.4C1.10321 23.4 0.6 22.8968 0.6 22.276V14.569C0.6 13.9483 1.10321 13.4451 1.72394 13.4451Z"
        fillRule="evenodd"
      />
    </g>
  ),
});

export default Activity;
