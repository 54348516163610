const baseStyle = {
  fontFamily: 'body',
  fontSize: 'md',
  fontWeight: 'normal',
  lineHeight: 'sm',
};

export default {
  baseStyle,
};
