/* eslint-disable import/prefer-default-export */
import { ApiCallMethods } from '../constants/api';
import {
  ApiResponse,
  CreateZenDeskTicketPayload,
  CreateFabricPayload,
  Fabric as FabricType,
  FabricInfo,
} from '../types/fabric';
import { callApi } from '../utils/api';
import { getBaseURL } from '../utils/federation';
import { getFabric, getSession } from '../utils/persist';

export const getFabrics = (): Promise<ApiResponse<FabricType[]>> => {
  const fabric = getFabric();
  const url = `${getBaseURL()}/_fabric/${fabric}/_api/database/user`;
  return callApi(ApiCallMethods.Get, url);
};

export const createFabric = (payload: CreateFabricPayload) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/database`;
  return callApi(ApiCallMethods.Post, url, payload);
};

export const deleteFabric = (fabricName: string) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/database/${fabricName}`;
  return callApi(ApiCallMethods.Delete, url);
};

export const getFabricInfo = (fabricName: string): Promise<ApiResponse<FabricInfo>> => {
  const url = `${getBaseURL()}/_fabric/${fabricName}/_api/database/metadata`;
  return callApi(ApiCallMethods.Get, url);
};

export const updateFabric = (fabricName: string, data = {}) => {
  const url = `${getBaseURL()}/_fabric/${fabricName}/_api/database/metadata`;
  return callApi(ApiCallMethods.Patch, url, data);
};

export const checkGlobalUrlStatus = (globalURL: string) => {
  const url = `https://api-${globalURL}/_fabric/_system/_api/version`;
  return callApi(ApiCallMethods.Get, url);
};

export const createZenDeskTicket = (payload: CreateZenDeskTicketPayload) => {
  const url = `${getBaseURL()}/_api/trigger/zendesk`;
  return callApi(ApiCallMethods.Post, url, payload);
};

export const getFabricPermission = (fabricName: string) => {
  const { username, tenant } = getSession();
  const url = `${getBaseURL()}/_api/user/${tenant}.${username}/database/${fabricName}`;
  return callApi(ApiCallMethods.Get, url);
};

export const getCurrentUserFabrics = () => {
  const url = `${getBaseURL()}/_api/user/${getSession().username}/database`;
  return callApi(ApiCallMethods.Get, url);
};
