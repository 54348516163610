import { Alert, Container, Flex, Text } from '@chakra-ui/react';
import { Suspense } from 'react';
import { NavigateFunction, Outlet, RouteObject, useNavigate, useRoutes } from 'react-router-dom';
import { ProtectedAppRoutes, RouteDetail } from 'routing-details';
import { OpenAppRoutes, RouteTypes } from './constants/route';
import AppSpinner from './elements/AppSpinner';
import PageContainer from './elements/page/PageContainer';
import Sidebar from './elements/page/Sidebar';
import RequireAuth from './elements/RequireAuth';
import { useConfig } from './providers/configContext';
import RouteComponentMappings, { getProtectedRoutes } from './pages/AllRouteDetails';
import { StringConstants } from './constants/userMessages';
import AngleRight from './assets/icons/AngleRight';
import { isFreePlan } from './utils/helper';
import { StandardPlans } from './constants/common';
import CircleWithRight from './assets/icons/CircleWithRight';

const { AVANT_GRADE_UPGRADE_MESSAGE, AVANT_GRADE_UPGRADE_REQUEST_BANNER_MESSAGE } = StringConstants;

const getRoutes = (
  userConfiguration: RouteDetail[],
  navigate: NavigateFunction,
  showAccountUpgradeBanner: boolean,
  isAvantGardeRequested: boolean,
) => {
  const routes: RouteObject[] = [];

  const OpenPageLayout = (
    <Container centerContent>
      <Suspense fallback={<AppSpinner />}>
        <Outlet />
      </Suspense>
    </Container>
  );

  const MaintenancePageLayout = (
    <Suspense fallback={<AppSpinner />}>
      <Outlet />
    </Suspense>
  );

  const navigateToAccountPage = () => {
    navigate(`${ProtectedAppRoutes.Account}`, {
      state: { showUpgradeModal: true },
    });
  };

  const ProtectedPageLayout = (
    <>
      {(showAccountUpgradeBanner || isAvantGardeRequested) && (
        <Alert
          alignItems="center"
          bg="primary.50"
          border="1px"
          borderColor="neutral.200"
          cursor="pointer"
          data-cy="accountUpgradeBanner"
          display="flex"
          justifyContent="center"
          p={2}
          onClick={showAccountUpgradeBanner ? navigateToAccountPage : undefined}
        >
          {showAccountUpgradeBanner && (
            <>
              <Text color="positive" fontSize="sm" lineHeight="6">
                You are in the Playground. {AVANT_GRADE_UPGRADE_MESSAGE.replace('.', '')}
              </Text>
              <AngleRight fill="primary.300" h={2.5} mt={0.5} w={2.5} />
            </>
          )}
          {isAvantGardeRequested && (
            <>
              <CircleWithRight fill="primary.300" mr={2.5} />
              <Text color="positive" fontSize="sm" lineHeight="6">
                {AVANT_GRADE_UPGRADE_REQUEST_BANNER_MESSAGE}
              </Text>
            </>
          )}
        </Alert>
      )}
      <RequireAuth>
        <Flex>
          <Sidebar isOpen />
          <PageContainer />
        </Flex>
      </RequireAuth>
    </>
  );

  const openRoutes: RouteObject = {
    element: OpenPageLayout,
    children: [],
  };

  Object.values(RouteComponentMappings[RouteTypes.Open]).forEach((routeDetails) => {
    if (routeDetails.route.path === OpenAppRoutes.Maintenance) {
      openRoutes.element = MaintenancePageLayout;
    }
    openRoutes.children?.push(routeDetails.route);
  });

  routes.push(openRoutes);

  const protectedRoutes: RouteObject = {
    element: ProtectedPageLayout,
    children: [],
  };

  const userProtectedRoutes = getProtectedRoutes(userConfiguration);

  userProtectedRoutes.forEach((routeDetails) => protectedRoutes.children?.push(routeDetails.route));

  routes.push(protectedRoutes);

  return routes;
};

function App() {
  const {
    userConfiguration,
    tenantDetails: { plan, privateGdnUrl },
    isAvantGardeFlowEnabled,
  } = useConfig();
  const navigate = useNavigate();
  const showAccountUpgradeBanner = isFreePlan(plan) && isAvantGardeFlowEnabled;
  const isAvantGardeRequested =
    !privateGdnUrl && plan === StandardPlans.SCALE && isAvantGardeFlowEnabled;

  const element = useRoutes(
    getRoutes(userConfiguration, navigate, showAccountUpgradeBanner, isAvantGardeRequested),
  );
  return element;
}

export default App;
