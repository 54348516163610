import { createIcon } from '@chakra-ui/react';

const ApiReference = createIcon({
  displayName: 'ApiReferenceIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        clipRule="evenodd"
        d="M14.6331 4.34736C15.0724 3.90802 15.7847 3.90802 16.2241 4.34736L23.0812 11.2045C23.5205 11.6438 23.5205 12.3562 23.0812 12.7955L16.2241 19.6526C15.7847 20.092 15.0724 20.092 14.6331 19.6526C14.1937 19.2133 14.1937 18.501 14.6331 18.0616L20.6947 12L14.6331 5.93835C14.1937 5.49901 14.1937 4.7867 14.6331 4.34736Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M9.36692 4.34736C9.80626 4.7867 9.80626 5.49901 9.36692 5.93835L3.30527 12L9.36692 18.0616C9.80626 18.501 9.80626 19.2133 9.36692 19.6526C8.92758 20.092 8.21527 20.092 7.77593 19.6526L0.918784 12.7955C0.479444 12.3562 0.479444 11.6438 0.918784 11.2045L7.77593 4.34736C8.21527 3.90802 8.92758 3.90802 9.36692 4.34736Z"
        fillRule="evenodd"
      />
    </g>
  ),
});

export default ApiReference;
