import { createIcon } from '@chakra-ui/react';

const Graph = createIcon({
  displayName: 'GraphIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        clipRule="evenodd"
        d="M17.7 5.94999C18.556 5.94999 19.25 5.25603 19.25 4.39999C19.25 3.54395 18.556 2.84999 17.7 2.84999C16.844 2.84999 16.15 3.54395 16.15 4.39999C16.15 5.25603 16.844 5.94999 17.7 5.94999ZM21.5 4.39999C21.5 6.49867 19.7987 8.19999 17.7 8.19999C15.6013 8.19999 13.9 6.49867 13.9 4.39999C13.9 2.30131 15.6013 0.599991 17.7 0.599991C19.7987 0.599991 21.5 2.30131 21.5 4.39999Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M18.65 21.15C20.0307 21.15 21.15 20.0307 21.15 18.65C21.15 17.2693 20.0307 16.15 18.65 16.15C17.2693 16.15 16.15 17.2693 16.15 18.65C16.15 20.0307 17.2693 21.15 18.65 21.15ZM23.4 18.65C23.4 21.2733 21.2734 23.4 18.65 23.4C16.0267 23.4 13.9 21.2733 13.9 18.65C13.9 16.0266 16.0267 13.9 18.65 13.9C21.2734 13.9 23.4 16.0266 23.4 18.65Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M6.3 15.45C8.20538 15.45 9.75 13.9054 9.75 12C9.75 10.0946 8.20538 8.54999 6.3 8.54999C4.39462 8.54999 2.85 10.0946 2.85 12C2.85 13.9054 4.39462 15.45 6.3 15.45ZM12 12C12 15.148 9.44802 17.7 6.3 17.7C3.15198 17.7 0.600002 15.148 0.600002 12C0.600002 8.85196 3.15198 6.29999 6.3 6.29999C9.44802 6.29999 12 8.85196 12 12Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M15.63 5.77996C15.9173 6.21076 15.8008 6.79283 15.37 7.08003L11.095 9.93003C10.6642 10.2172 10.0822 10.1008 9.79495 9.67002C9.50775 9.23921 9.62416 8.65715 10.055 8.36994L14.33 5.51994C14.7608 5.23274 15.3428 5.34915 15.63 5.77996Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M10.1058 14.0493C10.3513 13.5934 10.9198 13.4228 11.3757 13.6683L16.007 16.162C16.4628 16.4075 16.6334 16.9761 16.3879 17.432C16.1425 17.8878 15.5739 18.0584 15.118 17.8129L10.4868 15.3192C10.0309 15.0737 9.86034 14.5051 10.1058 14.0493Z"
        fillRule="evenodd"
      />
    </g>
  ),
});

export default Graph;
