import { HStack, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { FigureProps } from '../types/commonTypes';
import { toCamelCase } from '../utils/helper';

function Figures({ figures }: FigureProps) {
  return (
    <SimpleGrid columns={figures.length} m="6" spacing="4">
      {figures.map(({ label, value }) => {
        const splitValue = value.split(' ');

        return (
          <VStack
            bgColor="white"
            borderRadius="lg"
            data-cy="figures"
            h={20}
            key={label}
            p={4}
            spacing={1}
            textAlign="center"
          >
            <Text color="neutral.500" fontSize="sm" lineHeight={5}>
              {label}
            </Text>
            <HStack data-cy={toCamelCase(label)} spacing={1}>
              <Text color="neutral.600" fontSize="xl" lineHeight={6}>
                {splitValue[0]}
              </Text>
              {splitValue.length > 1 && (
                <Text color="neutral.500" fontSize="sm">
                  {splitValue[1].toUpperCase()}
                </Text>
              )}
            </HStack>
          </VStack>
        );
      })}
    </SimpleGrid>
  );
}

export default Figures;
