const baseStyle = {};

const sizes = {
  sm: {
    borderRadius: 'md',
    fontSize: 'md',
  },
  md: {
    borderRadius: 'md',
    fontSize: 'md',
  },
  lg: {
    borderRadius: 'md',
  },
};

const variants = {
  rounded_solid: {
    color: 'button_rounded_solid_color',
    bg: 'button_rounded_solid_bg_color',
    borderRadius: 'full',
    _hover: { bg: 'button_rounded_solid_hover_bg_color' },
    _focus: { bg: 'button_rounded_solid_hover_bg_color' },
    _active: { bg: 'button_rounded_solid_hover_bg_color' },
  },
  neutral: {
    color: 'button_neutral_color',
    bg: 'button_neutral_bg_color',
    _hover: {
      bg: 'button_neutral_hover_bg_color',
      _disabled: {
        bg: 'button_neutral_disabled_bg_color',
        color: 'button_neutral_disabled_color',
      },
    },
    _focus: { bg: 'button_neutral_hover_bg_color' },
    _active: { bg: 'button_neutral_hover_bg_color' },
    _disabled: {
      bg: 'button_neutral_disabled_bg_color',
      color: 'button_neutral_disabled_color',
    },
  },
  danger: {
    color: 'button_danger_color',
    bg: 'button_danger_bg_color',
    _hover: {
      bg: 'button_danger_hover_bg_color',
      _disabled: {
        bg: 'button_danger_bg_color',
        color: 'button_danger_color',
      },
    },
    _focus: { bg: 'button_danger_hover_bg_color' },
    _active: { bg: 'button_danger_hover_bg_color' },
  },
  outline: {
    color: 'positive',
    bg: 'transparent',
    border: '1px solid',
    borderColor: { color: 'primary.300' },
    _hover: {
      bg: 'transparent',
      color: 'positive_hover',
      borderColor: { color: 'positive_hover' },
    },
    _focus: {
      bg: 'transparent',
      color: 'positive_hover',
      borderColor: { color: 'positive_hover' },
    },
    _active: {
      bg: 'transparent',
      color: 'positive_hover',
      borderColor: { color: 'positive_hover' },
    },
  },
  link: {
    color: 'positive',
    bg: 'transparent',
    fontSize: 'sm',
    fontWeight: 'normal',
    h: 'auto',
    _hover: { color: 'positive_hover', textDecoration: 'none' },
    _focus: { color: 'positive_hover', textDecoration: 'none' },
    _active: { color: 'positive_hover', textDecoration: 'none' },
  },
};

export default {
  baseStyle,
  sizes,
  variants,
};
