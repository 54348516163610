export enum CollectionType {
  DOC = 'DOC',
  EDGE = 'EDGE',
  DYNAMO = 'DYNAMO',
  KV = 'KV',
  REDIS = 'REDIS',
}

export enum CollectionList {
  ALL = 'All Types',
  KEY_VALUE = 'Key-Value',
  DOCUMENT = 'Document',
  REDIS = 'Redis',
  DYNAMO = 'Dynamo',
  EDGE = 'Edge',
  SYSTEM = 'System',
}

export enum CollectionScreens {
  DATA = 'Data',
  INDEXES = 'Indexes',
  STREAM = 'Stream',
  SETTINGS = 'Settings',
}

export enum DocumentActions {
  EDIT = 'EDIT',
  IMPORT = 'IMPORT',
  EXPORT = 'EXPORT',
  REFRESH = 'REFRESH',
  FILTER = 'FILTER',
}

export enum FilterFields {
  COMPARATOR = 'attributeComparator',
  NAME = 'attributeName',
  VALUE = 'attributeValue',
}

export enum IndexTypes {
  GEO = 0,
  PERSISTENT = 1,
  FULLTEXT = 2,
  TTL = 3,
}

export enum IndexTypeValues {
  GEO = 'geo',
  PERSISTENT = 'persistent',
  FULLTEXT = 'fulltext',
  TTL = 'ttl',
  PRIMARY = 'primary',
  EDGE = 'edge',
}

export enum IndexItemType {
  INPUT = 'input',
  CHECKBOX = 'checkbox',
}

export const SortableDocumentsLimit = 12000;

export const DynamoHeaders = {
  DeleteItem: 'DynamoDB_20120810.DeleteItem',
  DynamoCreate: 'DynamoDB_20120810.CreateTable',
  DynamoDescribe: 'DynamoDB_20120810.DescribeTable',
  DynamoScan: 'DynamoDB_20120810.Scan',
  GetItem: 'DynamoDB_20120810.GetItem',
  PutItem: 'DynamoDB_20120810.PutItem',
  Query: 'DynamoDB_20120810.Query',
  UpdateTable: 'DynamoDB_20120810.UpdateTable',
  BatchWriteItem: 'DynamoDB_20120810.BatchWriteItem',
};

export enum DynamoAttributeTypes {
  STRING = 'S',
  NUMBER = 'N',
  BINARY = 'B',
}

export enum DynamoScanItemKeys {
  STRING = 'S',
  NUMBER = 'N',
  BOOLEAN = 'BOOL',
  BLOB = 'B',
}

export enum CollectionTypes {
  DOC = 2,
  EDGE = 3,
}

export enum CollectionModelTypes {
  REDIS = 3,
}

export enum DynamoFilterType {
  SCAN = 'scan',
  QUERY = 'query',
}

export enum DynamoFilterAttribute {
  COMPARATOR = 'comparator',
  KEYNAME = 'keyName',
  KEYTYPE = 'keyType',
  VALUE = 'value',
}

export enum KeySchemaType {
  HASH = 'HASH',
  RANGE = 'RANGE',
}

export enum DynamoKeywords {
  PrimaryKey = 'PrimaryKey',
  KeySchema = 'KeySchema',
  GlobalSecondaryIndexes = 'GlobalSecondaryIndexes',
  LocalSecondaryIndexes = 'LocalSecondaryIndexes',
}

export enum ConnectionType {
  DATA_SOURCE = 'Data Source',
  DATA_TARGET = 'Data Target',
}

export enum Connectors {
  Postgres = 'postgres',
  Oracle = 'oracle',
  Mongo = 'mongo',
  Macrometa = 'collection',
}

export enum ConnectionStatus {
  RUNNING = 'running',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
  ERROR = 'error',
  TERMINATED = 'terminated',
}

export enum CollectionDeleteModalType {
  COLLECTION = 'Collection',
  SQL = 'SQL',
  CHANGE_CONNECTION = 'ChangeConnection',
  CONNECTION = 'Connection',
}
