import {
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  HStack,
  Flex,
} from '@chakra-ui/react';
import { AppModalProps } from '../types/appModal';

/**
 * AppModal provides both Custom and Default behavior
 * Custom can have own header and footer
 * Default has its own header and footer which are controlled by props
 */
function AppModal({
  isOpen,
  onClose,
  size,
  modalTitle,
  children,
  closeText,
  onCloseComplete,
  primaryText,
  primaryColorScheme,
  formSubmitId,
  onClickPrimary,
  isPrimaryDisabled,
  otherActions,
  modalFooter,
  modalHeader,
  hideHeader,
  closeIconColor,
  hideCloseIcon,
  extras,
  modalContentWidth,
}: AppModalProps): JSX.Element {
  const defaultFooter = () => (
    <Flex justifyContent="space-between">
      {closeText && (
        <Button variant="neutral" onClick={onClose}>
          {closeText}
        </Button>
      )}
      <HStack>
        {otherActions}
        {!!primaryText && (
          <Button
            colorScheme={primaryColorScheme}
            data-cy={primaryText}
            disabled={isPrimaryDisabled}
            form={formSubmitId}
            type={formSubmitId ? 'submit' : 'button'}
            onClick={onClickPrimary}
          >
            {primaryText}
          </Button>
        )}
      </HStack>
    </Flex>
  );

  return (
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      scrollBehavior="outside"
      size={size}
      onClose={onClose}
      onCloseComplete={onCloseComplete}
    >
      <ModalOverlay />
      <ModalContent
        alignItems={size === 'full' ? 'center' : 'unset'}
        borderRadius={extras?.modalContentBorderRadius}
        data-cy="appModal"
      >
        {!hideHeader && (
          <ModalHeader color="modal_header_color">{modalHeader || modalTitle}</ModalHeader>
        )}
        {!hideCloseIcon && <ModalCloseButton color={closeIconColor} />}
        <ModalBody
          pb={extras?.modalBodyPadding?.bottom}
          pl={extras?.modalBodyPadding?.left}
          pr={extras?.modalBodyPadding?.right}
          pt={extras?.modalBodyPadding?.top}
          w={modalContentWidth}
        >
          {children}
        </ModalBody>
        <ModalFooter display="block" w={modalContentWidth}>
          {modalFooter || defaultFooter()}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default AppModal;

AppModal.defaultProps = {
  closeText: 'Close',
  primaryText: '',
  primaryColorScheme: null,
  formSubmitId: '',
  onClickPrimary: () => null,
  otherActions: null,
  modalHeader: null,
  modalFooter: null,
  isPrimaryDisabled: false,
  hideHeader: false,
  closeIconColor: 'inherit',
};
