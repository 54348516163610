import { createIcon } from '@chakra-ui/react';

const Minus = createIcon({
  displayName: 'Minus',
  viewBox: '0 0 16 16',
  path: [
    <path
      clipRule="evenodd"
      d="M8 0C3.58173 0 0 3.58173 0 8C0 12.4183 3.58173 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58173 12.4183 0 8 0ZM12.1154 8.80769H3.88462V7.19231H12.1154V8.80769Z"
      fillRule="evenodd"
    />,
  ],
});

export default Minus;
