const baseStyle = {
  color: 'positive',
  _hover: { textDecoration: 'none', color: 'positive_hover' },
};

const variants = {
  rounded_solid: {
    color: 'button_rounded_solid_color',
    bg: 'button_rounded_solid_bg_color',
    borderRadius: '20',
    px: '4',
    fontWeight: 'semibold',
    fontSize: 'sm',
    lineHeight: '8',
    height: '8',
    verticalAlign: 'middle',
    _hover: { color: 'button_rounded_solid_color', bg: 'button_rounded_solid_hover_bg_color' },
    _focus: { color: 'button_rounded_solid_color', bg: 'button_rounded_solid_hover_bg_color' },
    _active: { color: 'button_rounded_solid_color', bg: 'button_rounded_solid_hover_bg_color' },
  },
};

export default {
  baseStyle,
  variants,
};
