import { createIcon } from '@chakra-ui/react';

const Collection = createIcon({
  displayName: 'CollectionIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        clipRule="evenodd"
        d="M2.76756 4.51509C2.34743 4.89713 2.25 5.19242 2.25 5.38462C2.25 5.57681 2.34743 5.8721 2.76756 6.25414C3.18798 6.63645 3.85212 7.02663 4.75758 7.37488C6.56184 8.06883 9.12212 8.51923 12 8.51923C14.8779 8.51923 17.4382 8.06883 19.2424 7.37488C20.1479 7.02663 20.812 6.63645 21.2324 6.25414C21.6526 5.8721 21.75 5.57681 21.75 5.38462C21.75 5.19242 21.6526 4.89713 21.2324 4.51509C20.812 4.13278 20.1479 3.7426 19.2424 3.39435C17.4382 2.7004 14.8779 2.25 12 2.25C9.12212 2.25 6.56184 2.7004 4.75758 3.39435C3.85212 3.7426 3.18798 4.13278 2.76756 4.51509ZM4.03962 1.52766C6.1261 0.725165 8.94082 0.25 12 0.25C15.0592 0.25 17.8739 0.725165 19.9604 1.52766C21.0003 1.92762 21.9104 2.42837 22.578 3.03539C23.2458 3.64266 23.75 4.43507 23.75 5.38462C23.75 6.33416 23.2458 7.12657 22.578 7.73385C21.9104 8.34086 21.0003 8.84161 19.9604 9.24158C17.8739 10.0441 15.0592 10.5192 12 10.5192C8.94082 10.5192 6.1261 10.0441 4.03962 9.24158C2.99972 8.84161 2.08956 8.34086 1.42202 7.73385C0.754192 7.12657 0.25 6.33416 0.25 5.38462C0.25 4.43507 0.754192 3.64266 1.42202 3.03539C2.08956 2.42837 2.99972 1.92762 4.03962 1.52766Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M1.25 4.38462C1.80228 4.38462 2.25 4.83233 2.25 5.38462V18.6154C2.25 18.8072 2.34729 19.1023 2.7675 19.4844C3.18794 19.8667 3.85209 20.2569 4.75756 20.6052C6.5618 21.2993 9.12207 21.75 12 21.75C14.8779 21.75 17.4382 21.2993 19.2424 20.6052C20.1479 20.2569 20.8121 19.8667 21.2325 19.4844C21.6527 19.1023 21.75 18.8072 21.75 18.6154V5.38462C21.75 4.83233 22.1977 4.38462 22.75 4.38462C23.3023 4.38462 23.75 4.83233 23.75 5.38462V18.6154C23.75 19.5648 23.2457 20.357 22.578 20.9641C21.9105 21.5711 21.0004 22.0718 19.9605 22.4719C17.8741 23.2745 15.0594 23.75 12 23.75C8.94062 23.75 6.12589 23.2745 4.03946 22.4719C2.99959 22.0718 2.08949 21.5711 1.42201 20.9641C0.7543 20.357 0.25 19.5648 0.25 18.6154V5.38462C0.25 4.83233 0.697715 4.38462 1.25 4.38462Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M1.25 11C1.80228 11 2.25 11.4477 2.25 12C2.25 12.1918 2.34729 12.4869 2.7675 12.869C3.18794 13.2513 3.85209 13.6415 4.75756 13.9899C6.5618 14.6839 9.12207 15.1346 12 15.1346C14.8779 15.1346 17.4382 14.6839 19.2424 13.9899C20.1479 13.6415 20.8121 13.2513 21.2325 12.869C21.6527 12.4869 21.75 12.1918 21.75 12C21.75 11.4477 22.1977 11 22.75 11C23.3023 11 23.75 11.4477 23.75 12C23.75 12.9494 23.2457 13.7416 22.578 14.3488C21.9105 14.9557 21.0004 15.4565 19.9605 15.8565C17.8741 16.6591 15.0594 17.1346 12 17.1346C8.94062 17.1346 6.12589 16.6591 4.03946 15.8565C2.99959 15.4565 2.08949 14.9557 1.42201 14.3488C0.7543 13.7416 0.25 12.9494 0.25 12C0.25 11.4477 0.697715 11 1.25 11Z"
        fillRule="evenodd"
      />
    </g>
  ),
});

export default Collection;
