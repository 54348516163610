const baseStyle = {
  borderRadius: 'md',
};

const defaultProps = {
  focusBorderColor: 'primary.400',
};

const sizes = {
  sm: {
    borderRadius: 'md',
    fontSize: 'md',
  },
  md: {
    borderRadius: 'md',
    fontSize: 'md',
  },
  lg: {
    borderRadius: 'md',
  },
};

export default {
  baseStyle,
  defaultProps,
  sizes,
};
