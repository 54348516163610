import { Button, Center, Text, VStack } from '@chakra-ui/react';
import { memo, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

function ErrorView({ reset }: { reset: () => void }) {
  const location = useLocation();
  const errorOccurredPathname = useRef(location.pathname);

  useEffect(() => {
    if (!errorOccurredPathname.current) {
      errorOccurredPathname.current = location.pathname;
    }

    if (errorOccurredPathname.current !== location.pathname) {
      reset();
    }
  }, [location.pathname, reset]);

  return (
    <Center bg="white" h="100vh">
      <VStack>
        <Text fontSize="4xl">Something went wrong.</Text>
        <Button onClick={reset}>Retry</Button>
      </VStack>
    </Center>
  );
}

export default memo(ErrorView);
