import { createIcon } from '@chakra-ui/react';

const Filter = createIcon({
  displayName: 'FilterIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        clipRule="evenodd"
        d="M0.110836 0.542418C0.28224 0.209347 0.625416 0 1 0H23C23.3746 0 23.7178 0.209347 23.8892 0.542418C24.0606 0.875489 24.0315 1.27642 23.8137 1.58124L15.5385 13.1666V19.6154C15.5385 19.9497 15.3714 20.262 15.0932 20.4474L10.0162 23.8321C9.70939 24.0366 9.31484 24.0557 8.98968 23.8817C8.66453 23.7077 8.46154 23.3688 8.46154 23V13.1666L0.18627 1.58124C-0.0314543 1.27642 -0.0605684 0.875489 0.110836 0.542418ZM2.94319 2L10.2753 12.2649C10.3964 12.4345 10.4615 12.6377 10.4615 12.8462V21.1315L13.5385 19.0802V12.8462C13.5385 12.6377 13.6036 12.4345 13.7247 12.2649L21.0568 2H2.94319Z"
        fillRule="evenodd"
      />
    </g>
  ),
});

export default Filter;
