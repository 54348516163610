import { ApiCallMethods } from '../constants/api';
import { Fabrics, Tenants } from '../constants/common';
import { callApi } from '../utils/api';
import { getBaseURL } from '../utils/federation';
import { getSession } from '../utils/persist';

export const getLocations = (fabric: string) => {
  let dbIdentifier = fabric;
  const tenantName = getSession().tenant;
  if (tenantName !== Tenants.MM) {
    dbIdentifier = `${tenantName}.${dbIdentifier}`;
  }
  const url = `${getBaseURL()}/_fabric/${Tenants.MM}.${
    Fabrics.System
  }/_api/document/_guestdbs/${dbIdentifier}`;
  return callApi(ApiCallMethods.Get, url);
};

export const getTenantEdgeLocations = (tenantName: string) => {
  const url = `${getBaseURL()}/_api/datacenter/_tenant/${tenantName}`;
  return callApi(ApiCallMethods.Get, url);
};

export const changeSpotStatus = (regionName: string, isSpot: string) => {
  const url = `${getBaseURL()}/_api/datacenter/${regionName}/${isSpot}`;
  return callApi(ApiCallMethods.Put, url);
};

export const getLocalEdgeLocation = () => {
  const url = `${getBaseURL()}/_api/datacenter/local`;
  return callApi(ApiCallMethods.Get, url);
};
