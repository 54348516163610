import { createIcon } from '@chakra-ui/react';

const Access = createIcon({
  displayName: 'AccessIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        clipRule="evenodd"
        d="M5.2 10.4615C4.80912 10.4615 4.5 10.7758 4.5 11.1538V21.3077C4.5 21.6858 4.80912 22 5.2 22H18.8C19.1909 22 19.5 21.6858 19.5 21.3077V11.1538C19.5 10.7758 19.1909 10.4615 18.8 10.4615H5.2ZM2.5 11.1538C2.5 9.66266 3.71311 8.46154 5.2 8.46154H18.8C20.2869 8.46154 21.5 9.66266 21.5 11.1538V21.3077C21.5 22.7989 20.2869 24 18.8 24H5.2C3.71311 24 2.5 22.7989 2.5 21.3077V11.1538Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M7.08722 2.02612C8.39095 0.728297 10.1582 1.19209e-07 12 1.19209e-07C13.8418 1.19209e-07 15.6091 0.728297 16.9128 2.02612C18.2167 3.3241 18.95 5.0855 18.95 6.92308V9.46154C18.95 10.0138 18.5023 10.4615 17.95 10.4615C17.3977 10.4615 16.95 10.0138 16.95 9.46154V6.92308C16.95 5.61886 16.4296 4.36715 15.5018 3.44354C14.5738 2.51978 13.3143 2 12 2C10.6857 2 9.42619 2.51978 8.49823 3.44354C7.57042 4.36715 7.05001 5.61886 7.05001 6.92308V9.46154C7.05001 10.0138 6.60229 10.4615 6.05001 10.4615C5.49773 10.4615 5.05001 10.0138 5.05001 9.46154V6.92308C5.05001 5.0855 5.78335 3.3241 7.08722 2.02612Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12 16.44C12.116 16.44 12.21 16.346 12.21 16.23C12.21 16.114 12.116 16.02 12 16.02C11.884 16.02 11.79 16.114 11.79 16.23C11.79 16.346 11.884 16.44 12 16.44ZM10.21 16.23C10.21 15.2414 11.0114 14.44 12 14.44C12.9886 14.44 13.79 15.2414 13.79 16.23C13.79 17.2186 12.9886 18.02 12 18.02C11.0114 18.02 10.21 17.2186 10.21 16.23Z"
        fillRule="evenodd"
      />
    </g>
  ),
});

export default Access;
