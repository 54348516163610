const baseStyle = {};

const defaultProps = {
  focusBorderColor: 'primary.400',
};

const sizes = {
  sm: {
    field: {
      borderRadius: 'md',
      fontSize: 'md',
    },
  },
  md: {
    field: {
      borderRadius: 'md',
      fontSize: 'md',
    },
  },
  lg: {
    field: {
      borderRadius: 'md',
      fontSize: 'base',
    },
  },
};

export default {
  baseStyle,
  defaultProps,
  sizes,
};
