import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import ThemeProvider from './providers/ThemeProvider';
import ToastProvider from './providers/ToastProvider';
import App from './App';
import QueryProvider from './providers/QueryProvider';
import ErrorBoundary from './elements/ErrorBoundary';
import 'jsoneditor/dist/jsoneditor.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css';
import 'focus-visible/dist/focus-visible';
import 'leaflet/dist/leaflet.css';
import ConfigProvider from './providers/ConfigProvider';
import { AxiosInterceptors } from './utils/AxiosInterceptors';
import 'virtual:fonts.css';
import './css/common.css';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryProvider>
        <ConfigProvider>
          <ThemeProvider>
            <ToastProvider>
              <AxiosInterceptors>
                <ErrorBoundary>
                  <App />
                </ErrorBoundary>
              </AxiosInterceptors>
            </ToastProvider>
          </ThemeProvider>
        </ConfigProvider>
      </QueryProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
