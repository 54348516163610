import { ApiCallMethods } from '../constants/api';
import {
  FxMetaData,
  FxQueryWorkerParams,
  FxStreamPublisherParams,
  FxStreamQueryParams,
  InvokeFxParamsType,
  ListFxResponse,
} from '../types/functionWorker';
import { callApi } from '../utils/api';
import { getBaseURL } from '../utils/federation';
import { getFabric } from '../utils/persist';

export const listFunctionWorkers = (type: string): Promise<ListFxResponse> => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/function`;
  return callApi(ApiCallMethods.Get, url, {}, { params: { type } });
};

export const deleteFunctionWorkerByName = (functionName: string) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/function/${functionName}`;
  return callApi(ApiCallMethods.Delete, url);
};

export const getFunctionWorkerByName = (functionName: string) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/function/${functionName}`;
  return callApi(ApiCallMethods.Get, url);
};

export const getFxMetaData = () => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/function/metadata`;
  return callApi(ApiCallMethods.Get, url);
};

export const createFxMetaData = (attribute: FxMetaData) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/function/metadata`;
  return callApi(ApiCallMethods.Post, url, attribute);
};

export const updateFxMetaData = (attribute: FxMetaData) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/function/metadata`;
  return callApi(ApiCallMethods.Put, url, attribute);
};

export const deleteFxMetaData = () => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/function/metadata`;
  return callApi(ApiCallMethods.Delete, url);
};

export const generateFWFromQueryWorker = (params: FxQueryWorkerParams) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/function/generate`;
  return callApi(ApiCallMethods.Post, url, {}, { params });
};

export const generateFWFromStreamPublisher = (params: FxStreamPublisherParams) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/function/generate/publisher`;
  return callApi(ApiCallMethods.Post, url, {}, { params });
};

export const generateFWFromStreamQuery = (params: FxStreamQueryParams) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/function/generate/query`;
  return callApi(ApiCallMethods.Post, url, {}, { params });
};

export const invokeFx = (name: string, params: InvokeFxParamsType) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/function/invoke/${name}`;
  return callApi(ApiCallMethods.Post, url, {}, { params });
};

export const uploadFxBundle = (name: string, data: FormData) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/function/upload/${name}`;
  return callApi(ApiCallMethods.Post, url, data, {}, [{ 'Content-Type': 'multipart/form-data' }]);
};

export const getFxBundleVersions = (name: string) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/function/versions/${name}`;
  return callApi(ApiCallMethods.Get, url);
};

export const downloadFxBundle = (name: string, version: string) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/function/download/${name}`;
  return callApi(
    ApiCallMethods.Get,
    url,
    {},
    {
      params: {
        version,
      },
      responseType: 'blob',
    },
  );
};

export const activateFxBundle = (name: string, version: string) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/function/activate/${name}`;
  return callApi(
    ApiCallMethods.Post,
    url,
    {},
    {
      params: {
        version,
      },
    },
  );
};
