export const ALLOW_ONLY_SYMBOLS_REGEX = /^[a-zA-Z0-9\-_]*$/;
export const NAME_START_WITH_LETTERS_REGEX = /^[a-zA-Z]/;
export const NAME_LENGTH_REGEX = /^[a-zA-Z0-9\-_]{1,230}$/;
export const ONLY_ALLOW_LETTER_REGEX = /^[a-zA-Z\s]*$/;
export const ONLY_ALLOW_NUMBERS = /^-?[0-9]\d*(\.\d+)?$/;
export const NAME_SHOULD_BE_BETWEEN_ONE_TO_FORTY_CHARACTERS = /^[a-zA-Z0-9\-_]{1,40}$/u;
export const PASSWORD = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/u;
export const START_WITH_LETTER_ALPHA_NUMERIC_AND_UNDERSCORE = /^[a-zA-Z][a-zA-Z0-9_]*$/u;
export const DOCUMENT_KEY_REGEX = /^[-_!$%'()*+,.:;=@0-9a-zA-Z]+$/;
export const TEXT_STARTS_WITH_AT_THE_RATE = /^[@a-zA-Z0-9_]+$/;
export const TEXT_STARTS_WITH_DOLLAR = /^[/$a-zA-Z0-9_]+$/;
export const SPLIT_WITH_WHITE_SPACE_AT_THE_RATE = /@(@?\w+\d*)/u;
export const SPLIT_WITH_WHITE_SPACE_DOLLAR = /\$(\$?\w+\d*)/u;
export const QUERY_NAME_REGEX = /^(\s*[a-zA-Z0-9\-._]+\s*)+$/;
export const LETTERS_WITH_SPACES_AND_SPECIAL_CHARACTERS = /^[a-zA-Z )(&-]*$/;
export const EMAIL_REGEX =
  /^((([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+")))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
