import { createIcon } from '@chakra-ui/react';

const ChevronDown = createIcon({
  displayName: 'ChevronDownIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      clipRule="evenodd"
      d="M19.7852 8.65228C19.2727 8.13972 18.4416 8.13972 17.9291 8.65228L12 14.5813L6.07094 8.65228C5.55838 8.13972 4.72735 8.13972 4.21478 8.65228C3.70222 9.16484 3.70222 9.99587 4.21478 10.5084L11.0719 17.3656C11.5845 17.8781 12.4155 17.8781 12.9281 17.3656L19.7852 10.5084C20.2978 9.99587 20.2978 9.16484 19.7852 8.65228Z"
      fillRule="evenodd"
    />
  ),
});

export default ChevronDown;
