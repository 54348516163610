export var Tenants;
(function (Tenants) {
    Tenants["MM"] = "_mm";
    Tenants["Demo"] = "demo";
})(Tenants || (Tenants = {}));
export var Fabrics;
(function (Fabrics) {
    Fabrics["System"] = "_system";
})(Fabrics || (Fabrics = {}));
export var Users;
(function (Users) {
    Users["Root"] = "root";
})(Users || (Users = {}));
export var AccountTabKeys;
(function (AccountTabKeys) {
    AccountTabKeys["Overview"] = "overview";
    AccountTabKeys["Users"] = "users";
    AccountTabKeys["APIkeys"] = "apikeys";
    AccountTabKeys["Plans"] = "plans";
    AccountTabKeys["Settings"] = "settings";
})(AccountTabKeys || (AccountTabKeys = {}));
export var ApiReferenceKeys;
(function (ApiReferenceKeys) {
    ApiReferenceKeys["External"] = "external";
    ApiReferenceKeys["Internal"] = "internal";
})(ApiReferenceKeys || (ApiReferenceKeys = {}));
export var AccountDetailsTabKeys;
(function (AccountDetailsTabKeys) {
    AccountDetailsTabKeys["Back"] = "back";
    AccountDetailsTabKeys["General"] = "general";
    AccountDetailsTabKeys["Attributes"] = "attributes";
    AccountDetailsTabKeys["Permissions"] = "permissions";
})(AccountDetailsTabKeys || (AccountDetailsTabKeys = {}));
