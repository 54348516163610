const baseStyle = {};

const defaultProps = {
  colorScheme: 'primary',
  size: 'md',
};

export default {
  baseStyle,
  defaultProps,
};
