import * as yup from 'yup';
import { RequiredStringSchema } from 'yup/lib/string';
import {
  ALLOW_ONLY_SYMBOLS_REGEX,
  NAME_SHOULD_BE_BETWEEN_ONE_TO_FORTY_CHARACTERS,
  NAME_START_WITH_LETTERS_REGEX,
  DOCUMENT_KEY_REGEX,
} from '../constants/regex';
import { StringConstants } from '../constants/userMessages';

const {
  COLLECTION_NAME_REQUIRED,
  ALLOW_SYMBOLS_MESSAGE,
  COLLECTION_NAME_START_WITH_LETTERS_MESSAGE,
  COLLECTION_NAME_BETWEEN_ONE_TO_FORTY,
  COLLECTION_DOCUMENT_KEY_VALIDATION_MESSAGE,
  VALUE_ATTRIBUTE_REQUIRED,
  EXPIRE_AT_REQUIRED,
  PARTITION_KEY_REQUIRED,
} = StringConstants;

const createDocumentEdgeKVCollectionValidationSchema = yup.object({
  collectionName: yup
    .string()
    .required(COLLECTION_NAME_REQUIRED)
    .matches(NAME_START_WITH_LETTERS_REGEX, COLLECTION_NAME_START_WITH_LETTERS_MESSAGE)
    .matches(ALLOW_ONLY_SYMBOLS_REGEX, ALLOW_SYMBOLS_MESSAGE)
    .matches(NAME_SHOULD_BE_BETWEEN_ONE_TO_FORTY_CHARACTERS, COLLECTION_NAME_BETWEEN_ONE_TO_FORTY),
});

const createKVPairValidationSchema = (kvExpiration: boolean) => {
  let validationObj = {
    key: yup.string().matches(DOCUMENT_KEY_REGEX, COLLECTION_DOCUMENT_KEY_VALIDATION_MESSAGE),
    value: yup.string().required(VALUE_ATTRIBUTE_REQUIRED),
  } as { [key: string]: RequiredStringSchema<string | undefined> };

  if (kvExpiration) {
    validationObj = {
      ...validationObj,
      expireAt: yup.string().required(EXPIRE_AT_REQUIRED),
    };
  }

  return yup.object(validationObj);
};

const createDynamoIndexSchema = yup.object({
  indexName: yup.string().required().min(3),
  partitionKey: yup.string().required(PARTITION_KEY_REQUIRED),
});

const createDynamoCollectionSchema = yup.object({
  tableName: yup.string().required(),
  partitionKey: yup.string().required(),
  secondaryIndexes: yup.array().of(
    yup.object().shape({
      indexName: yup.string().required().min(3),
      partitionKey: yup.string().required(),
    }),
  ),
});

export {
  createDocumentEdgeKVCollectionValidationSchema,
  createKVPairValidationSchema,
  createDynamoIndexSchema,
  createDynamoCollectionSchema,
};
