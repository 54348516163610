import dayjs from 'dayjs';
import { Vendors } from 'routing-details';
import { Feature } from '../constants/apiReference';
import {
  CollectionType,
  CollectionTypes,
  Connectors,
  DynamoAttributeTypes,
  DynamoKeywords,
  ConnectionType,
  KeySchemaType,
} from '../constants/collections';
import { Tenants, Users, PermissionType, Fabrics, StandardPlans } from '../constants/common';
import {
  Attribute,
  AttributeDefinitions,
  CreateDynamoTable,
  DocumentBindVars,
  DynamoFilterAttributes,
  DynamoIndex,
} from '../types/collections';
import { getFabric, getRedirectURL, getSession, setCookieValueToSubDomain } from './persist';

import MacrometaLogo from '../assets/images/logos/macrometa.png?url';
import CoxLogo from '../assets/images/logos/cox.png?url';
import Postgres from '../assets/images/logos/postgressql.png?url';
import Oracle from '../assets/images/logos/oracle.png?url';
import Mongo from '../assets/images/logos/mongodb.png?url';
import Macrometa from '../assets/images/logos/macrometadark.png?url';
import { NumberConstants } from '../constants/userMessages';
import { Limits } from '../types/limits';
import { TenantDetails } from '../types/tenants';
import { QueryType, SavedQueryTypeFilterList } from '../constants/queries';
import { SavedQuery } from '../types/queries';

export const getLogoImageUrl = (vendor: Vendors) => {
  let logoUrl;
  switch (vendor) {
    case Vendors.Macrometa:
      logoUrl = MacrometaLogo;
      break;
    case Vendors.Cox:
      logoUrl = CoxLogo;
      break;
    default:
      logoUrl = MacrometaLogo;
  }
  return logoUrl;
};

const getUpdatedObjectValues = (key: string, a: any, b: any) => {
  const splitted = key.split('.');
  const len = splitted.length;
  let i = 0;
  let x = a;
  let y = b;
  while (i < len) {
    x = x[splitted[i]];
    y = y[splitted[i]];
    i += 1;
  }

  return { x, y };
};

export const sortAscending = (key: string) => (a: any, b: any) => {
  const { x, y } = getUpdatedObjectValues(key, a, b);
  const type = typeof x;

  switch (type) {
    case 'number':
      return x - y;
    case 'string':
      return x.toLowerCase() > y.toLowerCase() ? 1 : -1;
    default:
      return x > y ? 1 : -1;
  }
};

export const sortDescending = (key: string) => (a: any, b: any) => {
  const { x, y } = getUpdatedObjectValues(key, a, b);
  const type = typeof x;
  if (type === 'number') {
    return y - x;
  }

  return x > y ? -1 : 1;
};

export const getCollectionName = (type: number) =>
  type === CollectionTypes.DOC ? CollectionType.DOC : CollectionType.EDGE;

export const getEnumKey = (value: Feature): string =>
  Object.keys(Feature)[Object.values(Feature).indexOf(value)];

export const getImmediatePath = (pathname: string, basePath: string, step: number = 1) => {
  const splitted = pathname.split('/');
  const basePathIndex = splitted.indexOf(basePath);
  return splitted[basePathIndex + step];
};

export const convertToTitleCase = (value: string) => {
  const result = value.replace(/([A-Z]{1,})/g, ' $1');
  return `${result.charAt(0).toUpperCase()}${result.slice(1)}`;
};

export const getDisableStatusByPermission = (permission: string) =>
  permission === PermissionType.READ_WRITE;

export const isAdmin = () => {
  const { tenant, username } = getSession();
  const fabric = getFabric();

  return tenant === Tenants.MM && username === Users.Root && fabric === Fabrics.System;
};

export const isNonRoot = () => {
  const { username } = getSession();
  return username !== Users.Root;
};

export const convertLimitsKeyToLabel = (value: string) =>
  value
    .split(/(?=[A-Z])/)
    .map((val) => val.charAt(0).toUpperCase() + val.slice(1))
    .join(' ')
    .replace('M B', 'MB')
    .replace('K B', 'KB')
    .replace('T T L', 'TTL')
    // CPU is specified as millicore "m" in CAAS
    .replace(/\bM\b/g, 'm');

export const getCollectionStatus = (status?: number) => {
  switch (status) {
    case 0:
      return 'corrupted';
    case 1:
      return 'new born collection';
    case 2:
      return 'unloaded';
    case 3:
      return 'loaded';
    case 4:
      return 'unloading';
    case 5:
      return 'deleted';
    case 6:
      return 'loading';
    default:
      return 'unknown';
  }
};

export const isSystemCollection = (name: string) => name.substring(0, 1) === '_';

export const parseInput = (data: string) => {
  let parsed = null;
  try {
    parsed = JSON.parse(data);
  } catch (e) {
    parsed = data;
  }

  return parsed;
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const getMarkerColorToken = (
  local: boolean,
  status: number,
  url: string,
  isExisting: boolean = true,
) => {
  if (!url) {
    return 'unreachable_region_fill';
  }
  let markerColorToken = 'reachable_region_fill';
  if (local) {
    markerColorToken = isExisting ? 'local_region_fill' : 'disabled_region_fill';
  } else {
    markerColorToken = isExisting ? markerColorToken : 'disabled_region_fill';
    markerColorToken = status ? 'unreachable_region_fill' : markerColorToken;
  }
  return markerColorToken;
};

export const formatAmount = (amount: number) => {
  const parts = amount.toString().split('.');
  const numberPart = parts[0];
  const decimalPart = parts[1];
  const thousands = /\B(?=(\d{3})+(?!\d))/g;
  return `${numberPart.replace(thousands, ',')}.${decimalPart || '00'}`;
};

export const dayJsinUTC = (date: Date) =>
  // https://stackoverflow.com/questions/61368563/day-js-is-not-converting-utc-to-local-time
  dayjs(dayjs(date).toISOString().substring(0, 23)).format('MMM D');

export const isObjectsAreEqual = (object1: any, object2: any) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }

  let isEqual = true;

  keys1.forEach((key) => {
    if (object1[key] !== object2[key]) {
      isEqual = false;
    }
  });

  return isEqual;
};

export const normaliseDynamoTableData = (tableData: CreateDynamoTable | Record<string, any>) => {
  const normaliseDynamoTableDataArray: DynamoIndex[] = [];
  Object.keys(tableData).forEach((key: string) => {
    let normaliseDynamoTableDataObject = {} as DynamoIndex;
    if (
      key === DynamoKeywords.KeySchema ||
      key === DynamoKeywords.GlobalSecondaryIndexes ||
      key === DynamoKeywords.LocalSecondaryIndexes
    ) {
      if (key !== DynamoKeywords.KeySchema) {
        tableData[key].forEach((element: Record<string, any>) => {
          let sortingKey = null;
          if (element.KeySchema[1]) {
            sortingKey =
              element.KeySchema[1].KeyType === KeySchemaType.RANGE
                ? element.KeySchema[1].AttributeName
                : element.KeySchema[0].AttributeName;
          }
          normaliseDynamoTableDataObject = {
            name: element.IndexName,
            partitionKey:
              element.KeySchema[0].KeyType === KeySchemaType.HASH
                ? element.KeySchema[0].AttributeName
                : element.KeySchema[1].AttributeName,
            sortingKey,
            type: key,
          };
          normaliseDynamoTableDataArray.push(normaliseDynamoTableDataObject);
        });
      } else {
        let sortingKey = null;
        if (tableData[key][1]) {
          sortingKey =
            tableData[key][1].KeyType === KeySchemaType.RANGE
              ? tableData[key][1].AttributeName
              : tableData[key][0].AttributeName;
        }
        normaliseDynamoTableDataObject = {
          name: '',
          partitionKey:
            tableData[key][0].KeyType === KeySchemaType.HASH
              ? tableData[key][0].AttributeName
              : tableData[key][1].AttributeName,
          sortingKey,
          type: DynamoKeywords.PrimaryKey,
        };
        normaliseDynamoTableDataArray.push(normaliseDynamoTableDataObject);
      }
    }
  });

  return normaliseDynamoTableDataArray;
};

export const getAttributes = () => {
  const data = sessionStorage.getItem('filterAttributes');
  return data ? (JSON.parse(data) as Attribute[]) : [];
};

export const getFiltersQuery = (bindVars: DocumentBindVars) => {
  let filtersQuery = '';
  const newBindVars = bindVars;
  const validAtrtributes = getAttributes().filter((item) => item.attributeName);
  if (validAtrtributes.length) {
    filtersQuery += 'FILTER';
  }
  validAtrtributes.forEach((item, index) => {
    if (item.attributeName) {
      const attribute = `attr${index.toString()}`;
      const param = `param${index.toString()}`;
      newBindVars[attribute] = item.attributeName;

      let value = item.attributeValue;
      try {
        value = JSON.parse(value);
      } catch (err) {
        value = String(value);
      }

      if (item.attributeComparator === 'LIKE') {
        newBindVars[param] = `%${value}%`;
      } else if (item.attributeComparator === 'IN' || item.attributeComparator === 'NOT IN') {
        if (typeof value === 'string' && value.indexOf(',') !== -1) {
          newBindVars[param] = value.split(',').map((v) => v.replace(/(^ +| +$)/g, ''));
        } else {
          newBindVars[param] = [value];
        }
      } else {
        newBindVars[param] = value;
      }

      filtersQuery += ` x.@${attribute} ${item.attributeComparator} @${param} ${
        index === validAtrtributes.length - 1 ? '' : '&&'
      }`;
    }
  });

  return { newBindVars, filtersQuery };
};

export const addEllipsis = (name: string, length?: number) => {
  const dataLength: number = length || NumberConstants.FILE_NAME_MAX_LENGTH;
  const updatedName = name.length > dataLength ? `${name.substring(0, dataLength)}...` : name;

  return updatedName;
};

export const isNestedObject = (data: Record<string, any>) => {
  let nestedObjectFound = false;

  if (!data[0]) {
    nestedObjectFound = true;
    return nestedObjectFound;
  }

  data.forEach((obj: any) => {
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === 'object') {
        nestedObjectFound = true;
        return '';
      }
      return '';
    });
  });

  return nestedObjectFound;
};

export const generateHash = async (message: string) => {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);

  // hash the message
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
};

export const checkEmptySubLimits = (limits: Record<string, any>) => {
  const subLimits = limits;
  Object.keys(subLimits).forEach((subLimit) => {
    subLimits[subLimit] = subLimits[subLimit] || 0;
  });
  return subLimits;
};

export const checkEmptyLimits = (limits: Limits) => {
  Object.keys(limits).forEach((limit) => {
    checkEmptySubLimits(limits[limit as keyof Limits]);
  });
  return limits;
};

export const setAutoLoginCookies = (token: string, fabricName: string, hostName?: string) => {
  const redirectURL = getRedirectURL();
  const host = hostName || window.location.host;

  setCookieValueToSubDomain(`jwt_${host}/`, token);
  setCookieValueToSubDomain(`selectedFabric_${host}/`, fabricName);
  if (redirectURL) {
    setCookieValueToSubDomain('hostURL', redirectURL);
  }
};

export const isCurrentLoggedInUser = (user: string) => {
  const { tenant, username } = getSession();

  return user === `${tenant}.${username}`;
};

export const getChangedValues = (
  updatedValues: Record<string, any>,
  initialValues: Record<string, any>,
) =>
  Object.entries(updatedValues).reduce((acc: Record<string, any>, [key, value]) => {
    const hasChanged = initialValues[key] !== value;

    if (hasChanged) {
      acc[key] = value;
    }

    return acc;
  }, {});

export const stringToArray = (fieldString: string) => {
  const fields: string[] = [];
  fieldString.split(',').forEach((field) => {
    const modifiedField = field.replace(/(^\s+|\s+$)/g, '');
    if (modifiedField !== '') {
      fields.push(modifiedField);
    }
  });
  return fields;
};

export const getModifiedExclusiveStartKey = (
  exclusiveStartKey: Record<string, any>,
  definitions: AttributeDefinitions[],
) => {
  let newExclusiveStartKey = {};
  const eskKeys = Object.keys(exclusiveStartKey);
  definitions.forEach((item: AttributeDefinitions) => {
    if (eskKeys.includes(item.AttributeName)) {
      newExclusiveStartKey = {
        ...newExclusiveStartKey,
        [item.AttributeName]: exclusiveStartKey[item.AttributeName],
      };
    }
  });

  return newExclusiveStartKey;
};

export const toCamelCase = (sentenceCase: string) => {
  let out = '';
  const splitted = sentenceCase.split(sentenceCase.includes('_') ? '_' : ' ');
  splitted.forEach((el, idx) => {
    if (el) {
      const add = el.toLowerCase();
      out += idx === 0 ? add : add[0].toUpperCase() + add.slice(1);
    }
  });
  return out;
};

export const getDynamoFilterQueryKeys = (validAttributes: DynamoFilterAttributes[]) => {
  let ExpressionAttributeNames: Record<string, any> = {};
  let ExpressionAttributeValues: Record<string, any> = {};
  let FilterExpression = '';

  validAttributes.forEach((item, index) => {
    if (item.keyName && item.value) {
      const attributeNameKey = `#${item.keyName}`;
      ExpressionAttributeNames = {
        ...ExpressionAttributeNames,
        [attributeNameKey]: item.keyName,
      };
      const attributeValueKey = `:${item.keyName}`;
      const attributeValue = {
        [item.keyType]:
          item.keyType === DynamoAttributeTypes.NUMBER ? String(Number(item.value)) : item.value,
      };

      ExpressionAttributeValues = {
        ...ExpressionAttributeValues,
        [attributeValueKey]: attributeValue,
      };

      FilterExpression += `${attributeNameKey} ${item.comparator} ${attributeValueKey} ${
        index === validAttributes.length - 1 ? '' : 'AND'
      } `;
    }
  });

  return { FilterExpression, ExpressionAttributeNames, ExpressionAttributeValues };
};

export const isFreePlan = (planType: string) =>
  [StandardPlans.PLAYGROUND, StandardPlans.FREE.toUpperCase()].includes(planType as StandardPlans);

export const showBanner = (tenantDetails: TenantDetails, isAvantGardeFlowEnabled: boolean) => {
  const { plan, privateGdnUrl } = tenantDetails;
  const showAccountUpgradeBanner = isFreePlan(plan) && isAvantGardeFlowEnabled;
  const isAvantGardeRequested =
    !privateGdnUrl && plan === StandardPlans.SCALE && isAvantGardeFlowEnabled;

  return showAccountUpgradeBanner || isAvantGardeRequested;
};

export const checkC8ql = (type: string) => type === QueryType.C8QL;

export const getCurrentTenant = () => {
  const { tenant } = getSession();
  return tenant;
};

export const isMMVendor = (vendor: string) => vendor === Vendors.Macrometa;

export const underScoreToTitleCase = (sentence: string) =>
  sentence
    .split('_')
    .join(' ')
    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

export const isDataSource = (type: string) => type === ConnectionType.DATA_SOURCE;

export const getConnectorLogo = (connector: string) => {
  switch (connector) {
    case Connectors.Postgres:
      return Postgres;
    case Connectors.Oracle:
      return Oracle;
    case Connectors.Mongo:
      return Mongo;
    case Connectors.Macrometa:
      return Macrometa;
    default:
      return '';
  }
};

export const getCurrentFabricName = () => {
  const currentFabric = getFabric();
  const currentFabricName = currentFabric.startsWith('_')
    ? currentFabric.substring(1, currentFabric.length)
    : currentFabric;

  return currentFabricName;
};

export const removeEmptyValueKeys = (obj: Record<string, any>) =>
  Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    return value !== '' && value !== null && value !== undefined ? { ...acc, [key]: value } : acc;
  }, {});

export const getQueriesFilteredByQueryType = (
  allData: SavedQuery[],
  name: SavedQueryTypeFilterList,
) => {
  let filteredData;

  switch (name) {
    case SavedQueryTypeFilterList.SQL:
      filteredData = allData.filter(({ type }) => type === QueryType.SQL);
      break;
    case SavedQueryTypeFilterList.C8QL:
      filteredData = allData.filter(({ type }) => type === QueryType.C8QL);
      break;
    default:
      filteredData = allData;
      break;
  }

  return filteredData;
};

export const hasCommonValue = (arr1: string[], arr2: string[]) =>
  arr1.some((value) => arr2.includes(value));
