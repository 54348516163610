import { createIcon } from '@chakra-ui/react';

const ChevronDoubleRight = createIcon({
  displayName: 'ChevronDoubleRightIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        clipRule="evenodd"
        d="M3.89083 4.21478C3.37827 4.72734 3.37827 5.55837 3.89083 6.07093L9.8199 12L3.89083 17.9291C3.37827 18.4416 3.37827 19.2727 3.89083 19.7852C4.4034 20.2978 5.23443 20.2978 5.74699 19.7852L12.6041 12.9281C13.1167 12.4155 13.1167 11.5845 12.6041 11.0719L5.74699 4.21478C5.23443 3.70221 4.4034 3.70221 3.89083 4.21478Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12.4623 4.21478C11.9497 4.72734 11.9497 5.55837 12.4623 6.07093L18.3913 12L12.4623 17.9291C11.9497 18.4416 11.9497 19.2727 12.4623 19.7852C12.9748 20.2978 13.8059 20.2978 14.3184 19.7852L21.1756 12.9281C21.6881 12.4155 21.6881 11.5845 21.1756 11.0719L14.3184 4.21478C13.8059 3.70221 12.9748 3.70221 12.4623 4.21478Z"
        fillRule="evenodd"
      />
    </g>
  ),
});

export default ChevronDoubleRight;
