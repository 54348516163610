import { ApiCallMethods } from '../constants/api';
import { ApiResponse } from '../types/commonTypes';
import { Limits } from '../types/limits';
import {
  Tenant,
  TenantUpdateDetails,
  TenantDetailsResponse,
  Features,
  LoginAsTenantPayload,
} from '../types/tenants';
import { callApi } from '../utils/api';
import { getBaseURL } from '../utils/federation';

export const getTenants = (): Promise<ApiResponse<Tenant>> => {
  const url = `${getBaseURL()}/_api/tenants`;
  return callApi(ApiCallMethods.Get, url);
};

export const deleteTenant = (tenantName: string) => {
  const url = `${getBaseURL()}/_api/tenant/${tenantName}`;
  return callApi(ApiCallMethods.Delete, url);
};

export const createTenant = (data: any) => {
  const url = `${getBaseURL()}/_api/tenant`;
  return callApi(ApiCallMethods.Post, url, data);
};

export const updateTenantDetails = (
  tenantName: string,
  tenantDetails: TenantUpdateDetails = {},
) => {
  const url = `${getBaseURL()}/_api/tenant/${tenantName}`;
  return callApi(ApiCallMethods.Patch, url, tenantDetails);
};

export const getTenantDetails = (tenantName: string): Promise<TenantDetailsResponse> => {
  const url = `${getBaseURL()}/_api/tenant/${tenantName}`;
  return callApi(ApiCallMethods.Get, url);
};

export const updateTenantFeatures = (tenantName: string, payload: Features) => {
  const url = `${getBaseURL()}/_api/features/tenant/${tenantName}`;
  return callApi(ApiCallMethods.Post, url, payload);
};

export const deleteTenantLimits = (tenantName: string) => {
  const url = `${getBaseURL()}/_api/limits/tenant/${tenantName}`;
  return callApi(ApiCallMethods.Delete, url);
};

export const updateTenantLimits = (tenantName: string, payload: Limits) => {
  const url = `${getBaseURL()}/_api/limits/tenant/${tenantName}`;
  return callApi(ApiCallMethods.Patch, url, payload);
};

export const loginAsTenant = (payload: LoginAsTenantPayload) => {
  const url = `${getBaseURL()}/tenant/authenticate`;
  return callApi(ApiCallMethods.Post, url, payload);
};
