import AceEditor from 'react-ace';
import 'mm-ace-custom-modes/src-noconflict/mode-json';
import 'mm-ace-custom-modes/src-noconflict/mode-aql';
import 'mm-ace-custom-modes/src-noconflict/mode-sql';
import 'mm-ace-custom-modes/src-noconflict/mode-stream';
import { AppAceEditorProps } from '../types/appAceEditor';

function AppAceEditor({
  name,
  mode,
  value,
  styles,
  onChange,
  onLoad,
  editorRef,
  readOnly,
  maxLines,
  hideGutter,
}: AppAceEditorProps) {
  return (
    <AceEditor
      data-cy={name}
      editorProps={{ $blockScrolling: true }}
      fontSize={14}
      maxLines={maxLines}
      mode={mode}
      name={name}
      readOnly={readOnly}
      ref={editorRef}
      setOptions={{ useWorker: false }}
      showGutter={!hideGutter}
      showPrintMargin={false}
      style={styles}
      value={value}
      enableBasicAutocompletion
      wrapEnabled
      onChange={onChange}
      onLoad={onLoad}
    />
  );
}

export default AppAceEditor;

AppAceEditor.defaultProps = {
  mode: 'json',
  value: undefined,
  onChange: undefined,
  onLoad: undefined,
  editorRef: undefined,
  styles: { width: '100%', height: '300px' },
  readOnly: false,
  maxLines: undefined,
};
