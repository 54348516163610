import { createIcon } from '@chakra-ui/react';

const Dashboard = createIcon({
  displayName: 'GridIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        clipRule="evenodd"
        d="M3.11538 2.5C2.77552 2.5 2.5 2.77552 2.5 3.11538V7.96154C2.5 8.30141 2.77552 8.57692 3.11538 8.57692H7.96154C8.30141 8.57692 8.57692 8.30141 8.57692 7.96154V3.11538C8.57692 2.77552 8.30141 2.5 7.96154 2.5H3.11538ZM0.5 3.11538C0.5 1.67095 1.67095 0.5 3.11538 0.5H7.96154C9.40598 0.5 10.5769 1.67095 10.5769 3.11538V7.96154C10.5769 9.40598 9.40598 10.5769 7.96154 10.5769H3.11538C1.67095 10.5769 0.5 9.40598 0.5 7.96154V3.11538Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M16.0385 2.5C15.6986 2.5 15.4231 2.77552 15.4231 3.11538V7.96154C15.4231 8.30141 15.6986 8.57692 16.0385 8.57692H20.8846C21.2245 8.57692 21.5 8.30141 21.5 7.96154V3.11538C21.5 2.77552 21.2245 2.5 20.8846 2.5H16.0385ZM13.4231 3.11538C13.4231 1.67095 14.594 0.5 16.0385 0.5H20.8846C22.3291 0.5 23.5 1.67095 23.5 3.11538V7.96154C23.5 9.40598 22.3291 10.5769 20.8846 10.5769H16.0385C14.594 10.5769 13.4231 9.40598 13.4231 7.96154V3.11538Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M3.11538 15.4231C2.77552 15.4231 2.5 15.6986 2.5 16.0385V20.8846C2.5 21.2245 2.77552 21.5 3.11538 21.5H7.96154C8.30141 21.5 8.57692 21.2245 8.57692 20.8846V16.0385C8.57692 15.6986 8.30141 15.4231 7.96154 15.4231H3.11538ZM0.5 16.0385C0.5 14.594 1.67095 13.4231 3.11538 13.4231H7.96154C9.40598 13.4231 10.5769 14.594 10.5769 16.0385V20.8846C10.5769 22.3291 9.40598 23.5 7.96154 23.5H3.11538C1.67095 23.5 0.5 22.3291 0.5 20.8846V16.0385Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M16.0385 15.4231C15.6986 15.4231 15.4231 15.6986 15.4231 16.0385V20.8846C15.4231 21.2245 15.6986 21.5 16.0385 21.5H20.8846C21.2245 21.5 21.5 21.2245 21.5 20.8846V16.0385C21.5 15.6986 21.2245 15.4231 20.8846 15.4231H16.0385ZM13.4231 16.0385C13.4231 14.594 14.594 13.4231 16.0385 13.4231H20.8846C22.3291 13.4231 23.5 14.594 23.5 16.0385V20.8846C23.5 22.3291 22.3291 23.5 20.8846 23.5H16.0385C14.594 23.5 13.4231 22.3291 13.4231 20.8846V16.0385Z"
        fillRule="evenodd"
      />
    </g>
  ),
});

export default Dashboard;
