import Button from './button';
import Checkbox from './checkbox';
import Drawer from './drawer';
import FormLabel from './formLabel';
import Heading from './heading';
import Input from './input';
import Link from './link';
import Modal from './modal';
import Radio from './radio';
import Select from './select';
import Switch from './switch';
import Text from './text';
import Textarea from './textarea';

export default {
  Button,
  Checkbox,
  Drawer,
  FormLabel,
  Heading,
  Input,
  Link,
  Modal,
  Radio,
  Select,
  Switch,
  Text,
  Textarea,
};
