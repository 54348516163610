/* eslint-disable import/prefer-default-export */
import { ApiCallMethods } from '../constants/api';
import { ApiResponse } from '../types/commonTypes';
import { Stream, StreamApiResponse } from '../types/stream';
import { callApi } from '../utils/api';
import { getBaseURL } from '../utils/federation';
import { getFabric, getSession } from '../utils/persist';

export const getStreams = (): Promise<ApiResponse<Stream>> => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/streams`;
  return callApi(ApiCallMethods.Get, url);
};

export const createStream = (streamName: string, isLocal: boolean) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/streams/persistent/stream/${streamName}?local=${isLocal}`;
  return callApi(ApiCallMethods.Post, url);
};

export const deleteStream = (streamName: string) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/streams/${streamName}`;
  return callApi(ApiCallMethods.Delete, url);
};

export const getStreamStats = (
  streamName: string,
  isLocal: boolean,
  persistent: string,
): Promise<StreamApiResponse<string>> => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/streams/${persistent}/stream/${streamName}/stats?local=${isLocal}`;
  return callApi(ApiCallMethods.Get, url);
};

export const getOTP = (): Promise<{ data: { otp: string } }> => {
  const url = `${getBaseURL()}/apid/otp`;
  return callApi(ApiCallMethods.Post, url);
};

export const getPermissions = (streamName: string) => {
  const url = `${getBaseURL()}/_api/user/${
    getSession().username
  }/database/${getFabric()}/stream/${streamName}`;
  return callApi(ApiCallMethods.Get, url);
};
