import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Heading, Icon } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import ChevronRight from '../assets/icons/ChevronRight';
import { AppBreadCrumbProps } from '../types/commonTypes';
import { addEllipsis, toCamelCase } from '../utils/helper';

function AppBreadcrumb({ data }: AppBreadCrumbProps) {
  const navigate = useNavigate();
  return (
    <Breadcrumb separator={<Icon as={ChevronRight} boxSize={4} fill="neutral.300" />} spacing={1}>
      {data.map((item, index) => (
        <BreadcrumbItem data-cy={toCamelCase(item.name)} key={item.name + item.path}>
          {data.length !== index + 1 ? (
            <BreadcrumbLink
              as={Heading}
              color="positive"
              isCurrentPage={data.length === index + 1}
              variant="appHeading"
              _hover={{
                textDecorationLine: 'none',
              }}
              onClick={() => item.path && navigate(item.path)}
            >
              {item.name}
            </BreadcrumbLink>
          ) : (
            <BreadcrumbLink
              as={Heading}
              cursor="default"
              variant="appHeading"
              _hover={{
                textDecorationLine: 'none',
              }}
              isCurrentPage
            >
              {addEllipsis(item.name)}
            </BreadcrumbLink>
          )}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
}

export default AppBreadcrumb;
