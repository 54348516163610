import { createIcon } from '@chakra-ui/react';

const AngleRight = createIcon({
  displayName: 'AngleRight',
  viewBox: '0 0 7 12',
  path: (
    <path
      clipRule="evenodd"
      d="M.935.81a.875.875 0 0 0 0 1.237L4.888 6 .935 9.953a.875.875 0 0 0 1.237 1.237L6.744 6.62a.875.875 0 0 0 0-1.238L2.172.81a.875.875 0 0 0-1.237 0Z"
      fillRule="evenodd"
    />
  ),
});

export default AngleRight;
