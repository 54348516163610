const baseStyle = {
  body: {
    flex: 1,
    pb: 4,
    px: 6,
    pt: 2,
  },
  closeButton: {
    insetEnd: 2,
    position: 'absolute',
    top: 2,
  },
  dialog: {
    borderRadius: 'xl',
    shadow: 'dark-lg',
  },
  footer: {
    px: 6,
    pb: 5,
    pt: 3,
  },
  header: {
    fontSize: '2xl',
    fontWeight: 'bold',
    lineHeight: 8,
    px: 6,
    py: 4,
  },
  overlay: {
    bg: 'blackAlpha.800',
  },
};

const sizes = {
  '3.75xl': {
    dialog: { maxW: '60rem' },
  },
};

export default {
  baseStyle,
  sizes,
};
