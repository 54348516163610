import { createIcon } from '@chakra-ui/react';

const Search = createIcon({
  displayName: 'SearchIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        clipRule="evenodd"
        d="M15.75 5.75C12.2982 5.75 9.5 8.54822 9.5 12C9.5 15.4518 12.2982 18.25 15.75 18.25C17.0463 18.25 18.2504 17.8554 19.2488 17.1797L22.0621 20.0943C22.4456 20.4916 23.0787 20.5028 23.4761 20.1193C23.8734 19.7357 23.8846 19.1026 23.5011 18.7053L20.7055 15.8091C21.5173 14.7546 22 13.4336 22 12C22 8.54822 19.2018 5.75 15.75 5.75ZM11.5 12C11.5 9.65279 13.4028 7.75 15.75 7.75C18.0972 7.75 20 9.65279 20 12C20 14.3472 18.0972 16.25 15.75 16.25C13.4028 16.25 11.5 14.3472 11.5 12Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M0 21C0 20.4477 0.447715 20 1 20H10C10.5523 20 11 20.4477 11 21C11 21.5523 10.5523 22 10 22H1C0.447715 22 0 21.5523 0 21Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M0 14.9913C0 14.439 0.447715 13.9913 1 13.9913H6C6.55228 13.9913 7 14.439 7 14.9913C7 15.5436 6.55228 15.9913 6 15.9913H1C0.447715 15.9913 0 15.5436 0 14.9913Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M0 3C0 2.44772 0.447715 2 1 2H11C11.5523 2 12 2.44772 12 3C12 3.55228 11.5523 4 11 4H1C0.447715 4 0 3.55228 0 3Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M0 8.98268C0 8.4304 0.447715 7.98268 1 7.98268H6C6.55228 7.98268 7 8.4304 7 8.98268C7 9.53497 6.55228 9.98268 6 9.98268H1C0.447715 9.98268 0 9.53497 0 8.98268Z"
        fillRule="evenodd"
      />
    </g>
  ),
});

export default Search;
