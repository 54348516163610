import { createIcon } from '@chakra-ui/react';

const Documentation = createIcon({
  displayName: 'DocumentationIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        clipRule="evenodd"
        d="M0.589294 3.42856C0.589294 2.80724 1.09297 2.30356 1.71429 2.30356H7.88572C9.27527 2.30356 10.6079 2.85556 11.5905 3.83811C12.573 4.82067 13.125 6.1533 13.125 7.54285V21.9428C13.125 22.5642 12.6213 23.0678 12 23.0678C11.3787 23.0678 10.875 22.5642 10.875 21.9428C10.875 21.4228 10.6684 20.9241 10.3007 20.5564C9.93302 20.1887 9.43431 19.9821 8.91429 19.9821H1.71429C1.09297 19.9821 0.589294 19.4785 0.589294 18.8571V3.42856ZM10.875 18.2165V7.54285C10.875 6.75004 10.5601 5.9897 9.99947 5.4291C9.43887 4.8685 8.67853 4.55356 7.88572 4.55356H2.83929V17.7321H8.91429C9.60378 17.7321 10.2758 17.9012 10.875 18.2165Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12.4096 3.83811C13.3921 2.85556 14.7247 2.30356 16.1143 2.30356H22.2857C22.907 2.30356 23.4107 2.80724 23.4107 3.42856V18.8571C23.4107 19.4785 22.907 19.9821 22.2857 19.9821H15.0857C14.5657 19.9821 14.067 20.1887 13.6993 20.5564C13.3316 20.9241 13.125 21.4228 13.125 21.9428C13.125 22.5642 12.6213 23.0678 12 23.0678C11.3787 23.0678 10.875 22.5642 10.875 21.9428V7.54285C10.875 6.1533 11.427 4.82067 12.4096 3.83811ZM13.125 18.2165C13.7242 17.9012 14.3962 17.7321 15.0857 17.7321H21.1607V4.55356H16.1143C15.3215 4.55356 14.5611 4.8685 14.0005 5.4291C13.4399 5.9897 13.125 6.75004 13.125 7.54285V18.2165Z"
        fillRule="evenodd"
      />
    </g>
  ),
});

export default Documentation;
