import { createIcon } from '@chakra-ui/react';

const CaretUp = createIcon({
  displayName: 'CaretUp',
  viewBox: '0 0 16 9',
  path: (
    <path d="M14.922 9H1.078C.12 9-.36 7.85.318 7.178L7.238.313a1.082 1.082 0 0 1 1.522 0l6.922 6.865C16.36 7.85 15.88 9 14.922 9Z" />
  ),
});

export default CaretUp;
