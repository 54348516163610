/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
// FIXME: this will add additional 25KBs to bundle. Try to resolve it via alternative means
// eslint-disable-next-line import/no-extraneous-dependencies
import 'regenerator-runtime/runtime';
import {
  HStack,
  InputGroup,
  Input,
  InputLeftElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Tooltip,
  Text,
  Flex,
  Button,
  Icon,
  Center,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {
  Row,
  TableOptions,
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useTable,
} from 'react-table';
import { DataTableProps } from '../../types/table';
import SearchInput from '../../assets/icons/SearchInput';
import { NumberConstants } from '../../constants/userMessages';
import { toCamelCase } from '../../utils/helper';
import Plus from '../../assets/icons/Plus';

const DEFAULT_PAGE_SIZE = 10;

function DataTable(props: DataTableProps) {
  const {
    columns,
    data,
    fetchData,
    totalRecordsCount,
    setData,
    header: HeaderComponent,
    footer: FooterComponent,
    extra,
    filterInputPlaceholder,
    createNewAction,
    handleCreateNewAction,
    refetch,
    getSelectedRowIds,
    hiddenColumns,
    handleRowClick,
    handleRowDoubleClick,
    hidePagination,
    tableLayout,
    hideToolTip,
    handleLimits,
    disableNextAndPrev,
    defaultPageSize = DEFAULT_PAGE_SIZE,
    splitComponent: SplitComponent,
    paginationStyles,
    shouldHighlightSelectedRow,
  } = props;

  const [selectedRowsId, setSelectedRowsId] = useState<Record<string, boolean>>({});
  const [prevSelectedRow, setPrevSelectedRow] = useState({} as Row<object>);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const hasLimits = typeof handleLimits === 'function';

  const tableOptions: TableOptions<object> = {
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize,
      hiddenColumns: hiddenColumns ?? [],
    },
    autoResetSelectedRows: false,
  };
  if (typeof totalRecordsCount === 'number') {
    tableOptions.manualPagination = true;
    // we are calculating the total number of pages based pageSize
    tableOptions.pageCount = Math.ceil(totalRecordsCount / pageSize);
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    rows,
    state: { pageIndex, globalFilter },
    preGlobalFilteredRows,
    setGlobalFilter,
    gotoPage,
    toggleRowSelected,
  } = useTable(tableOptions, useGlobalFilter, usePagination, useRowSelect);

  useEffect(() => {
    if (typeof fetchData === 'function') {
      fetchData({ pageIndex, pageSize });
    }
    rows.forEach((row) => {
      if (row.isSelected) {
        row.toggleRowSelected(false);
      }
    });
  }, [pageIndex, pageSize, fetchData, rows]);

  const [value, setValue] = useState(globalFilter || '');
  const isMultiRowsSelectable = typeof getSelectedRowIds === 'function';

  useEffect(() => {
    if (!isMultiRowsSelectable && Object.keys(selectedRowsId).length) {
      Object.keys(selectedRowsId).map((item) => toggleRowSelected(item, false));
      setSelectedRowsId({});
    }
  }, [isMultiRowsSelectable, selectedRowsId, toggleRowSelected]);

  const onChange = useAsyncDebounce((val) => {
    setGlobalFilter(val || undefined);
  }, 200);

  const onRowClicked = (row: Row<object>, event: React.MouseEvent) => {
    if (isMultiRowsSelectable) {
      if (row.isSelected) {
        delete selectedRowsId[`${row.id}`];
      } else {
        selectedRowsId[`${row.id}`] = true;
      }
      row.toggleRowSelected(!row.isSelected);
      setSelectedRowsId(selectedRowsId);
      getSelectedRowIds(selectedRowsId);
    } else if (handleRowDoubleClick && event.detail === 2) {
      handleRowDoubleClick(row, event);
    } else if (handleRowClick) {
      if (prevSelectedRow.id && prevSelectedRow.isSelected) {
        prevSelectedRow.toggleRowSelected(false);
      }
      setPrevSelectedRow(row);

      if (shouldHighlightSelectedRow) {
        row.toggleRowSelected(!row.isSelected);
      }
      handleRowClick(row, event);
    }
  };

  const checkCellValueLength = (cellValue: any) =>
    cellValue && JSON.stringify(cellValue).length > NumberConstants.FILE_NAME_MAX_LENGTH;

  const rowHoverCursor = handleRowClick ? 'pointer' : 'inherit';

  useEffect(() => {
    // clear filter on adding/deleting a new record
    setValue('');
  }, [data.length]);

  useEffect(() => {
    // resetting page index to its initial value, if the filtered results are less than default page size
    if (totalRecordsCount && totalRecordsCount < pageSize) {
      gotoPage(0);
    }
  }, [totalRecordsCount, gotoPage, pageSize]);

  const handleOnChangeLimits = (limit: string) => {
    setPageSize(Number(limit));
    if (hasLimits) {
      handleLimits(limit);
    }
  };

  return (
    <>
      {(extra?.inviteUsers || filterInputPlaceholder || HeaderComponent) && (
        <HStack justifyContent="space-between" pt="3" px="4">
          {extra?.inviteUsers && (
            <Text fontSize="sm" fontWeight="bold">
              Invited Users
            </Text>
          )}
          {filterInputPlaceholder && (
            <InputGroup maxWidth="56">
              <InputLeftElement pointerEvents="none">
                <SearchInput fill="neutral.300" fontSize="md" mb="1.5" ml="2" />
              </InputLeftElement>
              <Input
                data-cy={toCamelCase(filterInputPlaceholder)}
                focusBorderColor="positive"
                placeholder={filterInputPlaceholder}
                size="sm"
                value={value || ''}
                autoFocus
                onChange={(e) => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
              />
            </InputGroup>
          )}
          {HeaderComponent && (
            <HeaderComponent
              canNextPage={canNextPage}
              canPreviousPage={canPreviousPage}
              data={data}
              extra={extra}
              globalFilter={globalFilter}
              nextPage={nextPage}
              pageCount={pageCount}
              pageIndex={pageIndex}
              pageSize={pageSize}
              preGlobalFilteredRows={preGlobalFilteredRows}
              previousPage={previousPage}
              setData={setData}
              setGlobalFilter={setGlobalFilter}
              totalRecordsCount={totalRecordsCount}
            />
          )}
          {createNewAction && (
            <Button
              data-cy={toCamelCase(createNewAction)}
              leftIcon={<Icon as={Plus} fill="white" />}
              onClick={handleCreateNewAction}
            >
              {createNewAction}
            </Button>
          )}
        </HStack>
      )}
      <Flex>
        {page.length === 0 && extra?.showRefresh ? (
          <Center minH="520px" w="full">
            <VStack>
              <Text color="neutral.600" fontSize="md" fontWeight="semibold">
                No data to display
              </Text>
              <Button
                _active={{ bgColor: 'none' }}
                _hover={{ bgColor: 'none' }}
                bgColor="neutral.50"
                color="neutral.600"
                onClick={refetch && refetch}
              >
                Refresh
              </Button>
            </VStack>
          </Center>
        ) : (
          <TableContainer height="full" width={SplitComponent ? '50%' : 'full'}>
            <Table
              {...getTableProps()}
              sx={{
                tableLayout,
                marginTop: `${filterInputPlaceholder ? '3' : '0'}`,
              }}
            >
              <Thead borderBottom="1px solid" borderBottomColor="neutral.50">
                {headerGroups.map((headerGroup) => (
                  <Tr
                    {...headerGroup.getHeaderGroupProps()}
                    borderTop={`${filterInputPlaceholder ? '1px' : 'none'}`}
                    borderTopColor="neutral.100"
                  >
                    {headerGroup.headers.map((column) => (
                      <Th
                        {...column.getHeaderProps({
                          style: { width: column.width },
                        })}
                        borderColor="neutral.100"
                        fontSize="sm"
                        fontWeight="normal"
                        px={4}
                        py={4}
                        textTransform="uppercase"
                        whiteSpace="pre-wrap"
                        {...(page.length === 0 && {
                          borderBottom: '0',
                        })}
                        color="neutral.500"
                      >
                        {column.render('Header')}
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {page.map((row, index) => {
                  prepareRow(row);
                  const hasMoreDataThanPageSize = rows.length >= pageSize;
                  const isLastRow = page.length - 1 === index;

                  return (
                    <Tr
                      data-cy={`row${index}`}
                      _hover={{
                        backgroundColor: row.isSelected ? 'select_document_bg_color' : 'inherit',
                      }}
                      {...row.getRowProps()}
                      bg={row.isSelected ? 'select_document_bg_color' : 'inherit'}
                      color={row.isSelected ? 'primary.600' : 'inherit'}
                      cursor={isMultiRowsSelectable ? 'copy' : rowHoverCursor}
                      data-row-id={row.values.name || row.values.topic}
                      onClick={(e) => onRowClicked(row, e)}
                      onDoubleClick={(e) => onRowClicked(row, e)}
                    >
                      {row.cells.map((cell, cellIndex) => (
                        <Tooltip
                          key={cell.getCellProps().key}
                          placement="top-start"
                          label={
                            !hideToolTip &&
                            checkCellValueLength(JSON.stringify(cell.value)) &&
                            JSON.stringify(cell.value)
                          }
                          hasArrow
                        >
                          <Td
                            data-cell-id={cell.column.getHeaderProps().key}
                            {...cell.getCellProps()}
                            {...(cellIndex === 0 &&
                              (row.isSelected
                                ? {
                                    position: 'relative',
                                    fontWeight: 'semibold',
                                    color: 'primary.600',
                                  }
                                : {
                                    color: 'positive',
                                  }))}
                            borderBottomColor="neutral.100"
                            fontSize="md"
                            height="12"
                            lineHeight="10"
                            overflow="hidden"
                            px="4"
                            py="1"
                            whiteSpace="break-spaces"
                            borderBottom={
                              (hasMoreDataThanPageSize && SplitComponent && isLastRow) ||
                              (!SplitComponent && isLastRow)
                                ? '0'
                                : '1px'
                            }
                            textOverflow={
                              checkCellValueLength(JSON.stringify(cell.value)) && 'ellipsis'
                            }
                          >
                            {row.isSelected && cellIndex === 0 && (
                              <Text
                                as="span"
                                bg="primary.300"
                                display="inline-block"
                                h="56px"
                                left="0"
                                position="absolute"
                                top="0"
                                verticalAlign="middle"
                                w="1"
                              />
                            )}
                            {cell.render('Cell')}
                          </Td>
                        </Tooltip>
                      ))}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )}
        {SplitComponent && <SplitComponent />}
      </Flex>
      {FooterComponent && (
        <FooterComponent
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          data={data}
          disableNextAndPrev={disableNextAndPrev}
          extra={extra}
          globalFilter={globalFilter}
          gotoPage={gotoPage}
          handleLimits={hasLimits && handleOnChangeLimits}
          hidePagination={hidePagination}
          nextPage={nextPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          paginationStyles={paginationStyles}
          preGlobalFilteredRows={preGlobalFilteredRows}
          previousPage={previousPage}
          setData={setData}
          setGlobalFilter={setGlobalFilter}
          totalRecordsCount={totalRecordsCount ?? rows.length}
        />
      )}
    </>
  );
}

export default DataTable;

DataTable.defaultProps = {
  selectedRowIndexes: [],
  handleRowSelection: () => null,
  hiddenColumns: [],
  hidePagination: false,
  tableLayout: 'fixed',
  disableNextAndPrev: false,
  shouldHighlightSelectedRow: false,
};
