import { createIcon } from '@chakra-ui/react';

const Users = createIcon({
  displayName: 'UsersIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        clipRule="evenodd"
        d="M8.85716 4.42857C7.45672 4.42857 6.32144 5.56385 6.32144 6.96428C6.32144 8.36472 7.45672 9.5 8.85716 9.5C10.2576 9.5 11.3929 8.36472 11.3929 6.96428C11.3929 5.56385 10.2576 4.42857 8.85716 4.42857ZM4.32144 6.96428C4.32144 4.45928 6.35215 2.42857 8.85716 2.42857C11.3622 2.42857 13.3929 4.45928 13.3929 6.96428C13.3929 9.46929 11.3622 11.5 8.85716 11.5C6.35215 11.5 4.32144 9.46929 4.32144 6.96428Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M8.85715 14.6429C7.24691 14.6429 5.70261 15.2825 4.564 16.4211C3.42539 17.5598 2.78572 19.104 2.78572 20.7143V21.2857H14.9286V20.7143C14.9286 19.104 14.2889 17.5598 13.1503 16.4211C12.0117 15.2825 10.4674 14.6429 8.85715 14.6429ZM3.14979 15.0069C4.66347 13.4932 6.71647 12.6429 8.85715 12.6429C10.9978 12.6429 13.0508 13.4932 14.5645 15.0069C16.0782 16.5206 16.9286 18.5736 16.9286 20.7143V22.2857C16.9286 22.838 16.4809 23.2857 15.9286 23.2857H1.78572C1.23344 23.2857 0.785721 22.838 0.785721 22.2857V20.7143C0.785721 18.5736 1.6361 16.5206 3.14979 15.0069Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M14.1429 3.42857C14.1429 2.87629 14.5906 2.42857 15.1429 2.42857C16.3458 2.42857 17.4995 2.90644 18.3501 3.75705C19.2007 4.60766 19.6786 5.76134 19.6786 6.96428C19.6786 8.16723 19.2007 9.32091 18.3501 10.1715C17.4995 11.0221 16.3458 11.5 15.1429 11.5C14.5906 11.5 14.1429 11.0523 14.1429 10.5C14.1429 9.94771 14.5906 9.5 15.1429 9.5C15.8154 9.5 16.4603 9.23284 16.9359 8.7573C17.4114 8.28177 17.6786 7.6368 17.6786 6.96428C17.6786 6.29177 17.4114 5.6468 16.9359 5.17126C16.4604 4.69572 15.8154 4.42857 15.1429 4.42857C14.5906 4.42857 14.1429 3.98085 14.1429 3.42857Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M16.7226 13.5859C16.919 13.0697 17.4967 12.8104 18.0129 13.0068C19.5407 13.588 20.8561 14.6195 21.785 15.9647C22.7138 17.3099 23.2123 18.9054 23.2144 20.5401L23.2144 20.5414L23.2144 22.2857C23.2144 22.838 22.7667 23.2857 22.2144 23.2857H19.8573C19.305 23.2857 18.8573 22.838 18.8573 22.2857C18.8573 21.7334 19.305 21.2857 19.8573 21.2857H21.2144V20.5422C21.2127 19.3128 20.8378 18.1128 20.1392 17.1011C19.4405 16.0892 18.451 15.3133 17.3017 14.8761C16.7855 14.6797 16.5263 14.1021 16.7226 13.5859Z"
        fillRule="evenodd"
      />
    </g>
  ),
});

export default Users;
