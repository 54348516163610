import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ApiCallMethods } from '../constants/api';
import { Header } from '../types/commonTypes';
import { getSession } from './persist';
import axios from './AxiosInterceptors';

export const XC8Frontend = true;

// Common method to get headers. Optionally extra headers can be provided. By default it'll return authorization header only.
export const getHeaders = (optionalHeaders?: Header[]) => {
  const { token } = getSession();

  let headers = {
    Authorization: `bearer ${token}`,
    'x-c8-frontend': XC8Frontend,
  };
  if (optionalHeaders?.length) {
    optionalHeaders.forEach((item) => {
      headers = { ...headers, ...item };
    });
  }

  return headers;
};

// Common method for calling apis. data & config are optional. by default config will return authorization header only
export const callApi = async (
  method: ApiCallMethods,
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
  optionalHeaders?: Header[],
) => {
  let response = {} as AxiosResponse;
  let updatedConfig = {} as AxiosRequestConfig;
  if (config) {
    updatedConfig = {
      headers: getHeaders(optionalHeaders),
      ...config,
    };
  } else {
    updatedConfig = {
      headers: getHeaders(optionalHeaders),
    };
  }
  switch (method) {
    case ApiCallMethods.Get:
      response = await axios.get(url, updatedConfig);
      break;
    case ApiCallMethods.Post:
      response = await axios.post(url, data, updatedConfig);
      break;
    case ApiCallMethods.Put:
      response = await axios.put(url, data, updatedConfig);
      break;
    case ApiCallMethods.Delete:
      response = await axios.delete(url, updatedConfig);
      break;
    case ApiCallMethods.Patch:
      response = await axios.patch(url, data, updatedConfig);
      break;
    default:
      break;
  }

  return response;
};
