import { createIcon } from '@chakra-ui/react';

const Compute = createIcon({
  displayName: 'ComputeIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        clipRule="evenodd"
        d="M7.15385 6.63846C6.86921 6.63846 6.63846 6.86921 6.63846 7.15385V16.8462C6.63846 17.1308 6.86921 17.3615 7.15385 17.3615H16.8462C17.1308 17.3615 17.3615 17.1308 17.3615 16.8462V7.15385C17.3615 6.86921 17.1308 6.63846 16.8462 6.63846H7.15385ZM4.43846 7.15385C4.43846 5.65418 5.65418 4.43846 7.15385 4.43846H16.8462C18.3458 4.43846 19.5615 5.65418 19.5615 7.15385V16.8462C19.5615 18.3458 18.3458 19.5615 16.8462 19.5615H7.15385C5.65418 19.5615 4.43846 18.3458 4.43846 16.8462V7.15385Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M8.76923 0.5C9.32152 0.5 9.76923 0.947715 9.76923 1.5V5.53846C9.76923 6.09075 9.32152 6.53846 8.76923 6.53846C8.21695 6.53846 7.76923 6.09075 7.76923 5.53846V1.5C7.76923 0.947715 8.21695 0.5 8.76923 0.5Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M15.2308 0.5C15.7831 0.5 16.2308 0.947715 16.2308 1.5V5.53846C16.2308 6.09075 15.7831 6.53846 15.2308 6.53846C14.6785 6.53846 14.2308 6.09075 14.2308 5.53846V1.5C14.2308 0.947715 14.6785 0.5 15.2308 0.5Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M0.5 15.2308C0.5 14.6785 0.947715 14.2308 1.5 14.2308H5.53846C6.09075 14.2308 6.53846 14.6785 6.53846 15.2308C6.53846 15.7831 6.09075 16.2308 5.53846 16.2308H1.5C0.947715 16.2308 0.5 15.7831 0.5 15.2308Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M0.5 8.76923C0.5 8.21695 0.947715 7.76923 1.5 7.76923H5.53846C6.09075 7.76923 6.53846 8.21695 6.53846 8.76923C6.53846 9.32152 6.09075 9.76923 5.53846 9.76923H1.5C0.947715 9.76923 0.5 9.32152 0.5 8.76923Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M15.2308 17.4615C15.7831 17.4615 16.2308 17.9093 16.2308 18.4615V22.5C16.2308 23.0523 15.7831 23.5 15.2308 23.5C14.6785 23.5 14.2308 23.0523 14.2308 22.5V18.4615C14.2308 17.9093 14.6785 17.4615 15.2308 17.4615Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M8.76923 17.4615C9.32152 17.4615 9.76923 17.9093 9.76923 18.4615V22.5C9.76923 23.0523 9.32152 23.5 8.76923 23.5C8.21695 23.5 7.76923 23.0523 7.76923 22.5V18.4615C7.76923 17.9093 8.21695 17.4615 8.76923 17.4615Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M17.4615 8.76923C17.4615 8.21695 17.9093 7.76923 18.4615 7.76923H22.5C23.0523 7.76923 23.5 8.21695 23.5 8.76923C23.5 9.32152 23.0523 9.76923 22.5 9.76923H18.4615C17.9093 9.76923 17.4615 9.32152 17.4615 8.76923Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M17.4615 15.2308C17.4615 14.6785 17.9093 14.2308 18.4615 14.2308H22.5C23.0523 14.2308 23.5 14.6785 23.5 15.2308C23.5 15.7831 23.0523 16.2308 22.5 16.2308H18.4615C17.9093 16.2308 17.4615 15.7831 17.4615 15.2308Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M10.1923 12.8077C10.1923 12.2554 10.64 11.8077 11.1923 11.8077H14.4231C14.9754 11.8077 15.4231 12.2554 15.4231 12.8077C15.4231 13.36 14.9754 13.8077 14.4231 13.8077H11.1923C10.64 13.8077 10.1923 13.36 10.1923 12.8077Z"
        fillRule="evenodd"
      />
    </g>
  ),
});

export default Compute;
