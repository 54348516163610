import { Box, Text, Flex, Button, HStack } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
import { ProtectedAppRoutes } from 'routing-details';
import { commonDynamoAPI, deleteCollection, truncateCollection } from '../../../apis/collections';
import { DynamoHeaders } from '../../../constants/collections';
import { AlertStatus } from '../../../constants/common';
import { dynamicStringMessages } from '../../../constants/userMessages';
import AppAceEditor from '../../../elements/AceEditor';
import { useToast } from '../../../providers/toastContext';
import {
  CollectionParams,
  CreateDynamoTable,
  DynamoContextProps,
  DynamoTableDescription,
} from '../../../types/collections';
import { useConfig } from '../../../providers/configContext';

function DynamoSettings() {
  const { appToast, throwAppError } = useToast();

  const [dynamoTableData, setDynamoTableData] = useState({} as DynamoTableDescription);
  const params = useParams<keyof CollectionParams>() as CollectionParams;
  const [toPurge, setToPurge] = useState(false);
  const [toDelete, setToDelete] = useState(false);
  const navigate = useNavigate();
  const { setShowHeaderItem, hasCollectionPermission } = useOutletContext<DynamoContextProps>();
  const { hasFabricPermission } = useConfig();

  const { mutate: dynamoSettingsMutation } = useMutation(
    ({ data, action }: { data: CreateDynamoTable; action: string }) =>
      commonDynamoAPI(data, action),
  );

  useEffect(() => {
    setShowHeaderItem(false);
  }, [setShowHeaderItem]);

  const getdynamoDescribeData = useCallback(() => {
    const body = {
      data: { TableName: params.collection },
      action: DynamoHeaders.DynamoDescribe,
    };
    dynamoSettingsMutation(body, {
      onSuccess: (res) => {
        setDynamoTableData(res.data.Table);
      },
    });
  }, [dynamoSettingsMutation, params.collection]);

  useEffect(() => {
    getdynamoDescribeData();
  }, [getdynamoDescribeData]);

  const toggleDeleteConfirmation = () => {
    if (toPurge) {
      setToPurge(false);
    }
    setToDelete((value) => !value);
  };

  const togglePurgeConfirmation = () => {
    if (toDelete) {
      setToDelete(false);
    }
    setToPurge((value) => !value);
  };

  const deleteMutation = useMutation(() => deleteCollection(params.collection as string), {
    onError: (err: any) => {
      if (err.response.status === 404) {
        navigate('../../');
      }
      throwAppError(err);
    },
    onSuccess: () => {
      toggleDeleteConfirmation();
      appToast({
        alertDescription: dynamicStringMessages.getCollectionDeletedMessage(params.collection),
        alertStatus: AlertStatus.SUCCESS,
      });

      navigate(`/${ProtectedAppRoutes.Collections}`);
    },
  });

  const handleOnFinishPurge = () => {
    getdynamoDescribeData();
    togglePurgeConfirmation();
  };

  const truncate = useMutation('truncateCollection', () => truncateCollection(params.collection), {
    onError: (err) => throwAppError(err),
    onSuccess: () => {
      appToast({
        alertDescription: dynamicStringMessages.getCollectionTruncateMessage(params.collection),
        alertStatus: AlertStatus.SUCCESS,
      });
      handleOnFinishPurge();
    },
  });

  const handlePurge = () => {
    const body = {
      data: { TableName: params.collection },
      action: DynamoHeaders.DynamoDescribe,
    };
    dynamoSettingsMutation(body, {
      onSuccess: () => {
        if (dynamoTableData && dynamoTableData.AttributeDefinitions) {
          let ExpressionAttributeNames = {};
          let ProjectionExpression = '';
          dynamoTableData.AttributeDefinitions.forEach((item, index) => {
            ExpressionAttributeNames = {
              ...ExpressionAttributeNames,
              ...{ [`#KEY${index}`]: item.AttributeName },
            };
            ProjectionExpression +=
              dynamoTableData.AttributeDefinitions &&
              dynamoTableData.AttributeDefinitions.length - 1 === index
                ? `#KEY${index}`
                : `#KEY${index}, `;
          });
          const obj = {
            data: {
              ExpressionAttributeNames,
              ProjectionExpression,
              TableName: params.collection,
            },
            action: DynamoHeaders.DynamoScan,
          };
          dynamoSettingsMutation(obj, {
            onSuccess: (res) => {
              if (res.data.Items.length) {
                truncate.mutate();
              } else {
                handleOnFinishPurge();
              }
            },
          });
        }
      },
    });
  };

  return (
    <>
      <Box p="4">
        <Text color="neutal.700" fontSize="lg" fontWeight="bold" lineHeight="5">
          Table Schema
        </Text>
        {dynamoTableData && (
          <>
            <Box border="1px" borderColor="neutral.200" mt="4">
              <AppAceEditor
                maxLines={20}
                name="dynamoSettings"
                value={JSON.stringify(dynamoTableData, null, 2)}
                readOnly
                // FIXME: Editor content has to be selected by default
              />
            </Box>
            <Flex justify="end" pb="4" pt="6">
              <HStack>
                <Button
                  data-cy="delete"
                  disabled={!hasCollectionPermission || !hasFabricPermission}
                  variant="danger"
                  onClick={toggleDeleteConfirmation}
                >
                  Delete
                </Button>
                <Button
                  data-cy="purge"
                  disabled={!hasCollectionPermission}
                  variant="danger"
                  onClick={togglePurgeConfirmation}
                >
                  Purge
                </Button>
              </HStack>
            </Flex>
          </>
        )}
      </Box>
      {(toDelete || toPurge) && (
        <Flex align="center" bg="red.50" justify="space-between" p="3">
          <Text color="red.600" fontWeight="bold">
            Really {toDelete ? 'delete' : 'purge'} ?
          </Text>
          <HStack>
            <Button
              data-cy="no"
              variant="neutral"
              onClick={toDelete ? toggleDeleteConfirmation : togglePurgeConfirmation}
            >
              No
            </Button>
            <Button
              data-cy="yes"
              variant="danger"
              onClick={() => (toDelete ? deleteMutation.mutate() : handlePurge())}
            >
              Yes
            </Button>
          </HStack>
        </Flex>
      )}
    </>
  );
}

export default DynamoSettings;
