import { createIcon } from '@chakra-ui/react';

const APIKeys = createIcon({
  displayName: 'APIKeysIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        clipRule="evenodd"
        d="M12.6111 2.71429C10.3405 2.71429 8.26042 3.51907 6.63677 4.85997C6.21094 5.21166 5.58063 5.15154 5.22895 4.7257C4.87727 4.29987 4.93738 3.66956 5.36322 3.31788C7.33202 1.69192 9.85872 0.714287 12.6111 0.714287C18.901 0.714287 24 5.81327 24 12.1032C24 18.3931 18.901 23.4921 12.6111 23.4921C9.85872 23.4921 7.33202 22.5144 5.36322 20.8885C4.93738 20.5368 4.87727 19.9065 5.22895 19.4806C5.58063 19.0548 6.21094 18.9947 6.63677 19.3464C8.26042 20.6873 10.3405 21.4921 12.6111 21.4921C17.7964 21.4921 22 17.2885 22 12.1032C22 6.91784 17.7964 2.71429 12.6111 2.71429Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M4.28571 14.2857C5.54808 14.2857 6.57143 13.2624 6.57143 12C6.57143 10.7376 5.54808 9.71429 4.28571 9.71429C3.02335 9.71429 2 10.7376 2 12C2 13.2624 3.02335 14.2857 4.28571 14.2857ZM4.28571 16.2857C6.65265 16.2857 8.57143 14.3669 8.57143 12C8.57143 9.63307 6.65265 7.71429 4.28571 7.71429C1.91878 7.71429 0 9.63307 0 12C0 14.3669 1.91878 16.2857 4.28571 16.2857Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M6.71428 11.5714C6.71428 11.0191 7.16199 10.5714 7.71428 10.5714H17.1429C17.6951 10.5714 18.1429 11.0191 18.1429 11.5714C18.1429 12.1237 17.6951 12.5714 17.1429 12.5714H7.71428C7.16199 12.5714 6.71428 12.1237 6.71428 11.5714Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12.8571 10.5714C13.4094 10.5714 13.8571 11.0191 13.8571 11.5714V15.4286C13.8571 15.9809 13.4094 16.4286 12.8571 16.4286C12.3049 16.4286 11.8571 15.9809 11.8571 15.4286V11.5714C11.8571 11.0191 12.3049 10.5714 12.8571 10.5714Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M17.1429 10.5714C17.6951 10.5714 18.1429 11.0191 18.1429 11.5714V14.1429C18.1429 14.6951 17.6951 15.1429 17.1429 15.1429C16.5906 15.1429 16.1429 14.6951 16.1429 14.1429V11.5714C16.1429 11.0191 16.5906 10.5714 17.1429 10.5714Z"
        fillRule="evenodd"
      />
    </g>
  ),
});

export default APIKeys;
