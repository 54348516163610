import { Button, Flex, HStack, SimpleGrid, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { DeleteModalProps } from '../types/appDeleteModal';
import AppModal from './AppModal';
import { toCamelCase } from '../utils/helper';

function AppDeleteModal({
  isOpen,
  onClose,
  confirmationMessage,
  modalTitle,
  itemLabel,
  itemValue,
  onConfirmDelete,
  children,
  primaryText,
  secondaryText,
  shouldShowCustomButtons,
  onClickPrimary,
  modalSize,
}: DeleteModalProps) {
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    setShowConfirm(false);
  }, [isOpen]);

  const modalFooterWithCustomButtons = () => (
    <HStack>
      <Button data-cy="yes" variant="danger" onClick={onClickPrimary}>
        {primaryText}
      </Button>
      <Button data-cy="no" variant="neutral" onClick={onClose}>
        {secondaryText}
      </Button>
    </HStack>
  );

  const deleteConfirmation = () => (
    <Flex
      align="center"
      bg="red.50"
      data-cy="confirmationModal"
      justify="space-between"
      mt={5}
      p={3}
    >
      <Text color="red.600" fontWeight="bold" mr="4">
        {confirmationMessage}
      </Text>
      <HStack>
        <Button variant="neutral" onClick={() => setShowConfirm(false)}>
          No
        </Button>
        <Button
          data-cy="yes"
          variant="danger"
          onClick={() =>
            typeof onConfirmDelete === 'function' ? (onConfirmDelete(), onClose()) : onClose()
          }
        >
          Yes
        </Button>
      </HStack>
    </Flex>
  );

  const modalFooter = () => (
    <>
      <Flex justifyContent="space-between">
        <Button variant="neutral" onClick={onClose}>
          Close
        </Button>
        <Button
          data-cy={toCamelCase(primaryText as string)}
          variant="danger"
          onClick={() => setShowConfirm(true)}
        >
          {primaryText}
        </Button>
      </Flex>
      {showConfirm && deleteConfirmation()}
    </>
  );

  return (
    <AppModal
      isOpen={isOpen}
      modalFooter={shouldShowCustomButtons ? modalFooterWithCustomButtons() : modalFooter()}
      modalTitle={modalTitle}
      size={modalSize}
      onClose={onClose}
    >
      {children ?? (
        <SimpleGrid columns={2}>
          {itemLabel && <Text color="body_color">{itemLabel} :</Text>}
          <Text color="body_color">{itemValue}</Text>
        </SimpleGrid>
      )}
    </AppModal>
  );
}

export default AppDeleteModal;

AppDeleteModal.defaultProps = {
  confirmationMessage: 'Really delete?',
  children: null,
  itemLabel: '',
  primaryText: 'Delete',
  shouldShowCustomButtons: false,
  modalSize: '2xl',
};
