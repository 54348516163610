import { Spinner, Box } from '@chakra-ui/react';
import { AppSpinnerProps } from '../types/commonTypes';

function AppSpinner({ isTransparent, loaderPosition, height = '100vh' }: AppSpinnerProps) {
  return (
    <Box
      backgroundColor={isTransparent ? 'black' : 'unset'}
      h={height}
      left="0"
      opacity={isTransparent ? '0.7' : 'unset'}
      position={loaderPosition}
      top="0"
      w="100%"
      zIndex="999999"
    >
      <Spinner
        color="positive"
        left="50%"
        position="absolute"
        size="lg"
        top="50%"
        zIndex="999999"
      />
    </Box>
  );
}
export default AppSpinner;
