/**
 * Semantic tokens should be used if you see any theme color changes for component.
 * Do Not add commonValueTokens if not required.
 * Make sure to add common tokens to all new themes
 */

/**
 * We should follow below naming convention only for semantic tokens.
 * [component]_[state]_[CSSProperty]_[category]
 * ex. nav_item_active_bg_color
 */

const commonValueTokens = {
  avatar_bg: 'primary.300',
  body_bg_color: 'neutral.50',
  body_color: 'neutral.700',
  border_color: 'neutral.100',
  icon_color: 'primary.400',
  positive: 'primary.500',
  positive_hover: 'primary.600',
  subnav_color: 'neutral.500',
  subnav_selected_color: 'positive',
  subnav_hover_color: 'positive_hover',
  subnav_selected_border_color: 'positive',
  filter_tabs_active_border_color: 'positive',
  select_document_bg_color: 'primary.50',
  document_container_labels: 'body_color',
  json_editor_menu_bottom_border_color: '#c2c2c2',
  json_editor_button_inactive_bg_color: '#d3d3d3',
  button_danger_color: 'white',
  toolbar_icon_inactive: '#555',
  toolbar_export_icon: '#c09853',
  documents_table_header_color: '#717d90',
  no_document_danger_color: '#de1b1b',
  button_neutral_disabled_bg_color: '#D3D3D3',
  permission_row_bg_color: 'rgba(0, 0, 0, 0.1)',
  graph_color_1: 'blue.400',
  graph_color_2: 'indigo.400',
  graph_color_3: 'magenta.400',
  graph_color_4: 'orange.400',
  local_region_fill: 'green.400',
  reachable_region_fill: 'primary.400',
  unreachable_region_fill: 'red.400',
  disabled_region_fill: 'gray.400',
  modal_map_legend_bg_color: 'hsla(0,0%,100%,.68)',
  nav_icon_active_color: 'white',
  nav_item_active_color: 'white',
  nav_item_border_color: 'neutral.100',
  nav_item_hover_bg_color: 'primary.50',
  nav_item_icon_color: 'neutral.300',
  badge_success_bg_color: 'green.100',
  badge_success_color: 'green.700',
  badge_warning_bg_color: 'orange.100',
  badge_warning_color: 'orange.700',
  badge_default_bg_color: 'gray.100',
  badge_default_color: 'gray.700',
  badge_error_bg_color: 'red.100',
  badge_error_color: 'red.700',
  tab_item_base_color: 'neutral.500',
  tab_item_selected_color: 'neutral.700',
  tab_item_selected_border_color: 'primary.500',
};

export const macrometaTokens = {
  colors: {
    nav_body_bg_color: 'white',
    nav_item_color: 'neutral.600',
    nav_item_hover_color: 'neutral.600',
    nav_item_active_bg_color: 'positive',
    nav_icon_color: 'primary.400',
    nav_icon_opacity: 0.3,
    subnav_breadcrumb_color: 'neutral.700',
    filter_tabs_color: 'neutral.600',
    filter_tabs_active_color: 'primary.700',
    filter_tabs_active_bg_color: 'primary.100',
    button_rounded_solid_color: 'primary.600',
    button_rounded_solid_bg_color: 'primary.50',
    button_rounded_solid_hover_bg_color: 'primary.200',
    button_neutral_color: 'neutral.600',
    button_neutral_bg_color: 'neutral.50',
    button_neutral_hover_bg_color: 'neutral.200',
    button_neutral_disabled_color: 'neutral.600',
    button_danger_bg_color: 'red.500',
    button_danger_hover_bg_color: 'red.600',
    json_editor_mode_select_color: 'primary.600',
    json_editor_mode_select_bg_color: 'primary.50',
    json_editor_search_input_color: 'neutral.600',
    json_editor_search_input_border_color: 'neutral.200',
    modal_header_color: 'neutral.700',
    permission_header_color: 'gray.700',
    plus_circle_icon_color: 'primary.600',
    icon_default_color: 'primary.400',
    ...commonValueTokens,
  },
};

export const coxTokens = {
  colors: {
    nav_body_bg_color: '#EBEFF0',
    nav_item_color: 'positive',
    nav_item_hover_color: 'positive_hover',
    nav_item_active_bg_color: 'positive',
    nav_icon_color: 'positive',
    nav_icon_opacity: 1,
    subnav_breadcrumb_color: 'positive',
    // FIXME: update color palette using all below hash values then use colors here. Discuss with design team.
    filter_tabs_color: '#484c55',
    filter_tabs_active_color: 'white',
    filter_tabs_active_bg_color: 'positive',
    button_rounded_solid_color: 'white',
    button_rounded_solid_bg_color: 'positive',
    button_rounded_solid_hover_bg_color: 'positive_hover',
    button_neutral_color: 'white',
    button_neutral_bg_color: '#B9C9D2',
    button_neutral_hover_bg_color: '#828E96',
    button_neutral_disabled_color: 'white',
    button_danger_bg_color: '#e74c3c',
    button_danger_hover_bg_color: '#ec7063',
    json_editor_mode_select_color: 'white',
    json_editor_mode_select_bg_color: 'positive_hover',
    json_editor_search_input_color: '#484c55',
    json_editor_search_input_border_color: '#dfe4ea',
    modal_header_color: '#484c55',
    permission_header_color: 'positive',
    plus_circle_icon_color: 'white',
    icon_default_color: 'positive',
    ...commonValueTokens,
  },
};
