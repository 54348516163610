import { createIcon } from '@chakra-ui/react';

const Connections = createIcon({
  displayName: 'ConnectionsIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        clipRule="evenodd"
        d="M0.714279 2.50549C0.714279 1.95321 1.16199 1.50549 1.71428 1.50549H9.42857C9.98085 1.50549 10.4286 1.95321 10.4286 2.50549V10.2198C10.4286 10.7721 9.98085 11.2198 9.42857 11.2198H1.71428C1.16199 11.2198 0.714279 10.7721 0.714279 10.2198V2.50549ZM2.71428 3.50549V9.21978H8.42857V3.50549H2.71428Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M13.1429 22.088C13.1429 21.5357 13.5906 21.088 14.1429 21.088H21.8572C22.4095 21.088 22.8572 21.5357 22.8572 22.088C22.8572 22.6402 22.4095 23.088 21.8572 23.088H14.1429C13.5906 23.088 13.1429 22.6402 13.1429 22.088Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M13.1429 14.3737C13.1429 13.8214 13.5906 13.3737 14.1429 13.3737H21.8572C22.4095 13.3737 22.8572 13.8214 22.8572 14.3737C22.8572 14.926 22.4095 15.3737 21.8572 15.3737H14.1429C13.5906 15.3737 13.1429 14.926 13.1429 14.3737Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M13.1429 18.2308C13.1429 17.6785 13.5906 17.2308 14.1429 17.2308H21.8572C22.4095 17.2308 22.8572 17.6785 22.8572 18.2308C22.8572 18.7831 22.4095 19.2308 21.8572 19.2308H14.1429C13.5906 19.2308 13.1429 18.7831 13.1429 18.2308Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M18 0.714279C18.3788 0.714279 18.725 0.928282 18.8944 1.26707L23.1801 9.83849C23.3351 10.1485 23.3186 10.5166 23.1364 10.8114C22.9542 11.1063 22.6323 11.2857 22.2857 11.2857H13.7143C13.3677 11.2857 13.0458 11.1063 12.8636 10.8114C12.6814 10.5166 12.6649 10.1485 12.8199 9.83849L17.1056 1.26707C17.275 0.928282 17.6212 0.714279 18 0.714279ZM15.3323 9.28571H20.6677L18 3.95035L15.3323 9.28571Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M5.57142 15.3736C3.99347 15.3736 2.71428 16.6528 2.71428 18.2308C2.71428 19.8087 3.99347 21.0879 5.57142 21.0879C7.14938 21.0879 8.42857 19.8087 8.42857 18.2308C8.42857 16.6528 7.14938 15.3736 5.57142 15.3736ZM0.714279 18.2308C0.714279 15.5482 2.8889 13.3736 5.57142 13.3736C8.25395 13.3736 10.4286 15.5482 10.4286 18.2308C10.4286 20.9133 8.25395 23.0879 5.57142 23.0879C2.8889 23.0879 0.714279 20.9133 0.714279 18.2308Z"
        fillRule="evenodd"
      />
    </g>
  ),
});

export default Connections;
