import { createIcon } from '@chakra-ui/react';

const Data = createIcon({
  displayName: 'DataIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        clipRule="evenodd"
        d="M1.39995 11.6067C1.903 11.1037 2.58528 10.8211 3.2967 10.8211C3.90422 10.8211 4.3967 11.3136 4.3967 11.9211C4.3967 12.5286 3.90422 13.0211 3.2967 13.0211C3.16876 13.0211 3.04605 13.0719 2.95558 13.1624C2.86511 13.2528 2.81428 13.3755 2.81428 13.5035V19.8332C2.81428 19.9611 2.86511 20.0838 2.95558 20.1743C3.04605 20.2648 3.16876 20.3156 3.2967 20.3156H20.7033C20.8312 20.3156 20.9539 20.2648 21.0444 20.1743C21.1349 20.0838 21.1857 19.9611 21.1857 19.8332V13.5035C21.1857 13.3755 21.1349 13.2528 21.0444 13.1624C20.9539 13.0719 20.8312 13.0211 20.7033 13.0211C20.0958 13.0211 19.6033 12.5286 19.6033 11.9211C19.6033 11.3136 20.0958 10.8211 20.7033 10.8211C21.4147 10.8211 22.097 11.1037 22.6001 11.6067C23.1031 12.1098 23.3857 12.7921 23.3857 13.5035V19.8332C23.3857 20.5446 23.1031 21.2269 22.6001 21.7299C22.097 22.233 21.4147 22.5156 20.7033 22.5156H3.2967C2.58528 22.5156 1.903 22.233 1.39995 21.7299C0.896896 21.2269 0.614285 20.5446 0.614285 19.8332V13.5035C0.614285 12.7921 0.896896 12.1098 1.39995 11.6067Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M3.2967 3.52657C3.03027 3.52657 2.81428 3.74255 2.81428 4.00898V10.3387C2.81428 10.6051 3.03027 10.8211 3.2967 10.8211H20.7033C20.9697 10.8211 21.1857 10.6051 21.1857 10.3387V4.00898C21.1857 3.74255 20.9697 3.52657 20.7033 3.52657H3.2967ZM0.614285 4.00898C0.614285 2.52753 1.81524 1.32657 3.2967 1.32657H20.7033C22.1848 1.32657 23.3857 2.52753 23.3857 4.00898V10.3387C23.3857 11.8201 22.1848 13.0211 20.7033 13.0211H3.2967C1.81524 13.0211 0.614285 11.8201 0.614285 10.3387V4.00898Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M6.46154 7.38261C6.57685 7.38261 6.67033 7.28913 6.67033 7.17382C6.67033 7.05851 6.57685 6.96503 6.46154 6.96503C6.34623 6.96503 6.25275 7.05851 6.25275 7.17382C6.25275 7.28913 6.34623 7.38261 6.46154 7.38261ZM4.67033 7.17382C4.67033 6.18456 5.47228 5.38261 6.46154 5.38261C7.4508 5.38261 8.25275 6.18456 8.25275 7.17382C8.25275 8.16308 7.4508 8.96503 6.46154 8.96503C5.47228 8.96503 4.67033 8.16308 4.67033 7.17382Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M11.7912 7.17382C11.7912 6.62153 12.2389 6.17382 12.7912 6.17382H18.3297C18.882 6.17382 19.3297 6.62153 19.3297 7.17382C19.3297 7.7261 18.882 8.17382 18.3297 8.17382H12.7912C12.2389 8.17382 11.7912 7.7261 11.7912 7.17382Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M6.46154 16.8771C6.57685 16.8771 6.67033 16.7836 6.67033 16.6683C6.67033 16.553 6.57685 16.4595 6.46154 16.4595C6.34623 16.4595 6.25275 16.553 6.25275 16.6683C6.25275 16.7836 6.34623 16.8771 6.46154 16.8771ZM4.67033 16.6683C4.67033 15.6791 5.47228 14.8771 6.46154 14.8771C7.4508 14.8771 8.25275 15.6791 8.25275 16.6683C8.25275 17.6576 7.4508 18.4595 6.46154 18.4595C5.47228 18.4595 4.67033 17.6576 4.67033 16.6683Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M11.7912 16.6683C11.7912 16.116 12.2389 15.6683 12.7912 15.6683H18.3297C18.882 15.6683 19.3297 16.116 19.3297 16.6683C19.3297 17.2206 18.882 17.6683 18.3297 17.6683H12.7912C12.2389 17.6683 11.7912 17.2206 11.7912 16.6683Z"
        fillRule="evenodd"
      />
    </g>
  ),
});

export default Data;
