import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getCollectionFigures } from '../../../apis/collections';
import { getStreams, getStreamStats } from '../../../apis/stream';
import Figures from '../../../elements/Figures';
import StreamStatistic from '../../../elements/stream/StreamStats';
import { useToast } from '../../../providers/toastContext';
import { CollectionFigureProps } from '../../../types/collections';
import { Option } from '../../../types/graph';
import { Stream } from '../../../types/stream';
import { formatBytes } from '../../../utils/helper';

function CollectionFigures({
  collection,
  pathName,
  refetchCollectionMetrics,
  setRefetchCollectionMetrics,
}: CollectionFigureProps) {
  const { throwAppError } = useToast();
  const [collectionDataFigures, setCollectionDataFigures] = useState<Option[]>([]);
  const [collectionIndexFigures, setCollectionIndexFigures] = useState<Option[]>([]);
  const [streamInfo, setStreamInfo] = useState<Stream | undefined>(undefined);
  const isStreamPath = pathName === 'stream';

  const { refetch } = useQuery('getCollectionFigures', () => getCollectionFigures(collection), {
    onError: throwAppError,
    onSuccess: ({ data }) => {
      const { count } = data;
      const { documentsSize, indexes, viewIndexes } = data.figures;
      setCollectionDataFigures([
        { label: 'DOCUMENTS', value: count.toString() },
        { label: 'DATA STORAGE', value: formatBytes(documentsSize) },
      ]);
      setCollectionIndexFigures([
        { label: 'INDEXES', value: indexes.count.toString() },
        { label: 'INDEX STORAGE', value: formatBytes(indexes.size) },
        { label: 'SEARCH INDEXES', value: viewIndexes.count.toString() },
        { label: 'SEARCH INDEX STORAGE', value: formatBytes(viewIndexes.size) },
      ]);
    },
  });

  useEffect(() => {
    if (refetchCollectionMetrics) {
      refetch();
    }
    return () => setRefetchCollectionMetrics(false);
  }, [refetch, refetchCollectionMetrics, setRefetchCollectionMetrics]);

  useQuery('StreamsList', getStreams, {
    select: (res) => {
      const {
        data: { result },
      } = res;

      return {
        data: {
          result: [...result].filter(
            (item: { topic: string }) =>
              !(item.topic.includes('c8globals') || item.topic.includes('c8locals')),
          ),
        },
      };
    },
    onError: (err) => throwAppError(err),
    enabled: isStreamPath,
    onSuccess: ({ data: { result } }) => {
      setStreamInfo(result.find((stream) => stream.topic === collection));
    },
  });

  const { data: streamFigures } = useQuery(
    'getStreamStatsDetails',
    () => {
      if (streamInfo) {
        const topicName = streamInfo.topic;
        const isLocal = streamInfo.local;
        const persist = streamInfo.type === 4 ? 'persistent' : 'non-persistent';

        return getStreamStats(topicName, isLocal, persist);
      }

      return null;
    },
    {
      enabled: !!streamInfo,
      onError: (err) => throwAppError(err),
    },
  );

  return (
    <Box>
      {!['stream', 'settings'].includes(pathName) && (
        <Figures
          figures={pathName === 'indexes' ? collectionIndexFigures : collectionDataFigures}
        />
      )}
      {streamFigures && isStreamPath && (
        <StreamStatistic streamStatsData={streamFigures.data.result} />
      )}
    </Box>
  );
}

export default CollectionFigures;
