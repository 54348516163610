import { createIcon } from '@chakra-ui/react';

const ChevronDoubleLeft = createIcon({
  displayName: 'ChevronDoubleLeftIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        clipRule="evenodd"
        d="M20.1067 4.21478C20.6192 4.72734 20.6192 5.55837 20.1067 6.07093L14.1776 12L20.1067 17.9291C20.6192 18.4416 20.6192 19.2727 20.1067 19.7852C19.5941 20.2978 18.7631 20.2978 18.2505 19.7852L11.3934 12.9281C10.8808 12.4155 10.8808 11.5845 11.3934 11.0719L18.2505 4.21478C18.7631 3.70222 19.5941 3.70222 20.1067 4.21478Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M11.5352 4.21478C12.0478 4.72734 12.0478 5.55837 11.5352 6.07093L5.60616 12L11.5352 17.9291C12.0478 18.4416 12.0478 19.2727 11.5352 19.7852C11.0227 20.2978 10.1916 20.2978 9.67906 19.7852L2.82192 12.9281C2.30936 12.4155 2.30936 11.5845 2.82192 11.0719L9.67906 4.21478C10.1916 3.70222 11.0227 3.70222 11.5352 4.21478Z"
        fillRule="evenodd"
      />
    </g>
  ),
});

export default ChevronDoubleLeft;
