import { createIcon } from '@chakra-ui/react';

const Account = createIcon({
  displayName: 'AccountIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        clipRule="evenodd"
        d="M12 8.64C10.422 8.64 9.14285 9.91919 9.14285 11.4971C9.14285 13.0751 10.422 14.3543 12 14.3543C13.578 14.3543 14.8571 13.0751 14.8571 11.4971C14.8571 9.91919 13.578 8.64 12 8.64ZM7.14285 11.4971C7.14285 8.81462 9.31747 6.64 12 6.64C14.6825 6.64 16.8571 8.81462 16.8571 11.4971C16.8571 14.1797 14.6825 16.3543 12 16.3543C9.31747 16.3543 7.14285 14.1797 7.14285 11.4971Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M7.63715 19.225C8.95137 18.4238 10.4608 18 12 18C13.5392 18 15.0486 18.4238 16.3628 19.225C17.6771 20.0262 18.7453 21.1737 19.4504 22.5419C19.7035 23.0328 19.5106 23.6359 19.0197 23.8889C18.5288 24.1419 17.9257 23.949 17.6727 23.4581C17.1358 22.4164 16.3224 21.5427 15.3218 20.9327C14.3212 20.3227 13.1719 20 12 20C10.8281 20 9.67881 20.3227 8.67818 20.9327C7.67755 21.5427 6.86421 22.4164 6.32733 23.4581C6.07432 23.949 5.47124 24.1419 4.98032 23.8889C4.4894 23.6359 4.29654 23.0328 4.54955 22.5419C5.25468 21.1737 6.32293 20.0262 7.63715 19.225Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M5.92821 1.73356C7.75074 0.600491 9.85397 0 12 0C14.146 0 16.2493 0.600491 18.0718 1.73356C19.8943 2.86663 21.3634 4.48706 22.313 6.41159C23.2626 8.33611 23.6547 10.4879 23.445 12.6237C23.2353 14.7595 22.4321 16.794 21.1264 18.4971C20.7904 18.9353 20.1626 19.0182 19.7244 18.6822C19.2861 18.3462 19.2032 17.7185 19.5392 17.2802C20.6178 15.8733 21.2813 14.1926 21.4545 12.4283C21.6278 10.664 21.3039 8.88635 20.5194 7.29653C19.735 5.7067 18.5214 4.36808 17.0158 3.43207C15.5103 2.49606 13.7728 2 12 2C10.2272 2 8.48975 2.49606 6.98418 3.43207C5.47861 4.36808 4.26498 5.7067 3.48057 7.29653C2.69615 8.88635 2.37224 10.664 2.54547 12.4283C2.7187 14.1926 3.38216 15.8733 4.4608 17.2802C4.79684 17.7185 4.71394 18.3462 4.27564 18.6822C3.83735 19.0182 3.20964 18.9353 2.8736 18.4971C1.56787 16.794 0.764738 14.7595 0.555039 12.6237C0.34534 10.4879 0.737443 8.33611 1.687 6.41159C2.63656 4.48706 4.10568 2.86663 5.92821 1.73356Z"
        fillRule="evenodd"
      />
    </g>
  ),
});

export default Account;
