/* eslint-disable import/prefer-default-export */
import { AxiosResponse } from 'axios';
import { IThemeResponse } from 'routing-details';
import { ApiCallMethods } from '../constants/api';
import { callApi } from '../utils/api';

export const getThemeDetails = (): Promise<AxiosResponse<IThemeResponse>> =>
  callApi(ApiCallMethods.Get, '/theme');

export const getUserConfig = () => callApi(ApiCallMethods.Get, '/config');
