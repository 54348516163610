import { ApiCallMethods } from '../constants/api';
import { Plan, UpdatePlan, ApiResponse, InstancePlanDetails } from '../types/plans';
import { callApi } from '../utils/api';
import { getBaseURL } from '../utils/federation';

export const getPlans = () => {
  const url = `${getBaseURL()}/_api/plan`;
  return callApi(ApiCallMethods.Get, url);
};

export const createPlan = (plan: Plan) => {
  const url = `${getBaseURL()}/_api/plan`;
  return callApi(ApiCallMethods.Post, url, plan);
};

export const deletePlan = (plan: string) => {
  const url = `${getBaseURL()}/_api/plan/${plan}`;
  return callApi(ApiCallMethods.Delete, url);
};

export const updatePlan = (plan: Plan, planName: string) => {
  const { name, ...updatedDetails } = plan;
  const data = name === planName ? updatedDetails : plan;
  const url = `${getBaseURL()}/_api/plan/${planName}`;
  return callApi(ApiCallMethods.Patch, url, data);
};

export const updateTenantPlan = (plan: UpdatePlan) => {
  const url = `${getBaseURL()}/_api/plan/update`;
  return callApi(ApiCallMethods.Post, url, plan);
};

export const getPlanDetails = (): Promise<ApiResponse<InstancePlanDetails>> =>
  callApi(ApiCallMethods.Get, '/get-plan-details');
