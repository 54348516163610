import { createIcon } from '@chakra-ui/react';

const SearchInput = createIcon({
  displayName: 'SearchInputIcon',
  viewBox: '0 0 24 24',
  path: (
    <path d="M10.4579 1C5.23447 1 1 5.23447 1 10.4579C1 15.6814 5.23447 19.9159 10.4579 19.9159C12.625 19.9159 14.6218 19.1871 16.2166 17.9612L20.894 22.6387C21.3758 23.1204 22.1569 23.1204 22.6387 22.6387C23.1204 22.1569 23.1204 21.3758 22.6387 20.894L17.9612 16.2166C19.1871 14.6218 19.9159 12.625 19.9159 10.4579C19.9159 5.23447 15.6814 1 10.4579 1ZM3.46729 10.4579C3.46729 6.59711 6.59711 3.46729 10.4579 3.46729C14.3188 3.46729 17.4486 6.59711 17.4486 10.4579C17.4486 14.3188 14.3188 17.4486 10.4579 17.4486C6.59711 17.4486 3.46729 14.3188 3.46729 10.4579Z" />
  ),
});

export default SearchInput;
