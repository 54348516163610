import { createIcon } from '@chakra-ui/react';

const Location = createIcon({
  displayName: 'LocationIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        clipRule="evenodd"
        d="M15.6061 17.5135C17.6374 15.2294 19.175 12.4929 19.175 10.0039C19.175 6.28498 15.7925 2.95 12 2.95C8.20632 2.95 4.825 6.28226 4.825 10C4.825 12.4912 6.36292 15.2288 8.39407 17.5131C9.37565 18.6171 10.3893 19.5198 11.2118 20.129C11.5241 20.3603 11.7899 20.5353 12 20.6588C12.2101 20.5353 12.476 20.3603 12.7882 20.1291C13.6108 19.5198 14.6245 18.6172 15.6061 17.5135ZM11.6203 20.8521C11.6204 20.8518 11.6265 20.8495 11.6381 20.8459C11.626 20.8506 11.6201 20.8524 11.6203 20.8521ZM12.3619 20.8459C12.3735 20.8495 12.3796 20.8518 12.3798 20.8521C12.3799 20.8524 12.3741 20.8506 12.3619 20.8459ZM12 23C13.5 23 21.375 16.6 21.375 10.0039C21.375 5.03334 16.9706 0.75 12 0.75C7.02944 0.75 2.625 5.02944 2.625 10C2.625 16.6 10.5 23 12 23Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12 11.8388C12.9496 11.8388 13.7194 11.069 13.7194 10.1194C13.7194 9.1698 12.9496 8.4 12 8.4C11.0504 8.4 10.2806 9.1698 10.2806 10.1194C10.2806 11.069 11.0504 11.8388 12 11.8388ZM12 14.0388C14.1646 14.0388 15.9194 12.284 15.9194 10.1194C15.9194 7.95477 14.1646 6.2 12 6.2C9.83537 6.2 8.0806 7.95477 8.0806 10.1194C8.0806 12.284 9.83537 14.0388 12 14.0388Z"
        fillRule="evenodd"
      />
    </g>
  ),
});

export default Location;
