import { createIcon } from '@chakra-ui/react';

const FunctionIcon = createIcon({
  displayName: 'FunctionIcon',
  viewBox: '0 0 24 22',
  path: [
    <path
      clipRule="evenodd"
      d="M13.685.442a1 1 0 0 1 .712.958v6.253h3.889a1 1 0 0 1 .833 1.552l-7.682 11.604a1 1 0 0 1-1.834-.552v-6.253H5.714a1 1 0 0 1-.834-1.552L12.563.848a1 1 0 0 1 1.122-.406Zm-6.11 11.562h3.028a1 1 0 0 1 1 1v3.931l4.821-7.282h-3.027a1 1 0 0 1-1-1V4.722l-4.821 7.282Z"
      fillRule="evenodd"
    />,
    <path
      clipRule="evenodd"
      d="M4.843 2.416a1 1 0 0 1 .014 1.414A9.964 9.964 0 0 0 2 10.83a9.965 9.965 0 0 0 2.857 6.998 1 1 0 1 1-1.428 1.4A11.964 11.964 0 0 1 0 10.829c0-3.27 1.309-6.235 3.429-8.399a1 1 0 0 1 1.414-.014ZM19.157 2.416a1 1 0 0 1 1.414.014A11.964 11.964 0 0 1 24 10.83c0 3.269-1.309 6.235-3.429 8.398a1 1 0 0 1-1.428-1.4A9.965 9.965 0 0 0 22 10.83a9.964 9.964 0 0 0-2.857-6.999 1 1 0 0 1 .014-1.414Z"
      fillRule="evenodd"
    />,
  ],
});

export default FunctionIcon;
