import { createIcon } from '@chakra-ui/react';

const Support = createIcon({
  displayName: 'SupportIcon',
  viewBox: '0 0 24 24',
  path: (
    <g>
      <path
        clipRule="evenodd"
        d="M12 2.6143C6.81641 2.6143 2.61429 6.81643 2.61429 12C2.61429 17.1836 6.81641 21.3857 12 21.3857C17.1836 21.3857 21.3857 17.1836 21.3857 12C21.3857 6.81643 17.1836 2.6143 12 2.6143ZM0.814285 12C0.814285 5.82231 5.8223 0.814301 12 0.814301C18.1777 0.814301 23.1857 5.82231 23.1857 12C23.1857 18.1777 18.1777 23.1857 12 23.1857C5.8223 23.1857 0.814285 18.1777 0.814285 12Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12 8.78573C10.2248 8.78573 8.78571 10.2248 8.78571 12C8.78571 13.7752 10.2248 15.2143 12 15.2143C13.7752 15.2143 15.2143 13.7752 15.2143 12C15.2143 10.2248 13.7752 8.78573 12 8.78573ZM6.98571 12C6.98571 9.2307 9.23068 6.98573 12 6.98573C14.7693 6.98573 17.0143 9.2307 17.0143 12C17.0143 14.7693 14.7693 17.0143 12 17.0143C9.23068 17.0143 6.98571 14.7693 6.98571 12Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M4.0916 4.09161C4.44307 3.74014 5.01292 3.74014 5.36439 4.09161L9.72553 8.45275C10.077 8.80423 10.077 9.37407 9.72553 9.72555C9.37406 10.077 8.80421 10.077 8.45274 9.72555L4.0916 5.3644C3.74012 5.01293 3.74012 4.44308 4.0916 4.09161Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M14.2744 14.2745C14.6259 13.923 15.1958 13.923 15.5472 14.2745L19.9084 18.6356C20.2599 18.9871 20.2599 19.5569 19.9084 19.9084C19.5569 20.2599 18.9871 20.2599 18.6356 19.9084L14.2744 15.5473C13.923 15.1958 13.923 14.6259 14.2744 14.2745Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M19.9084 4.09161C20.2599 4.44308 20.2599 5.01293 19.9084 5.3644L15.5472 9.72555C15.1958 10.077 14.6259 10.077 14.2744 9.72555C13.923 9.37407 13.923 8.80423 14.2744 8.45275L18.6356 4.09161C18.9871 3.74014 19.5569 3.74014 19.9084 4.09161Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M19.1781 4.82192C19.5296 5.17339 19.5296 5.74324 19.1781 6.09471L15.5472 9.72555C15.1958 10.077 14.6259 10.077 14.2744 9.72555C13.923 9.37407 13.923 8.80423 14.2744 8.45275L17.9053 4.82192C18.2568 4.47045 18.8266 4.47045 19.1781 4.82192Z"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M9.72553 14.2745C10.077 14.6259 10.077 15.1958 9.72553 15.5473L5.36439 19.9084C5.01292 20.2599 4.44307 20.2599 4.0916 19.9084C3.74012 19.5569 3.74012 18.9871 4.0916 18.6356L8.45274 14.2745C8.80421 13.923 9.37406 13.923 9.72553 14.2745Z"
        fillRule="evenodd"
      />
    </g>
  ),
});

export default Support;
