import { createIcon } from '@chakra-ui/react';

const CircleWithRight = createIcon({
  displayName: 'CircleWithRightIcon',
  viewBox: '0 0 24 22',
  path: [
    <path d="M1.714 11.001c0-1.634.435-3.239 1.263-4.654a9.369 9.369 0 0 1 3.45-3.407 9.522 9.522 0 0 1 4.715-1.248c1.655 0 3.281.43 4.714 1.247a.867.867 0 0 0 1.166-.312.837.837 0 0 0-.308-1.153A11.263 11.263 0 0 0 9.688.094a11.198 11.198 0 0 0-6.424 3.128A10.947 10.947 0 0 0 .095 9.564c-.32 2.4.171 4.838 1.397 6.935a11.099 11.099 0 0 0 5.386 4.663 11.278 11.278 0 0 0 7.147.463 11.152 11.152 0 0 0 5.956-3.928 10.91 10.91 0 0 0 2.304-6.696.84.84 0 0 0-.251-.598.863.863 0 0 0-1.212 0 .84.84 0 0 0-.252.598 9.248 9.248 0 0 1-2.76 6.582 9.49 9.49 0 0 1-6.668 2.726 9.49 9.49 0 0 1-6.666-2.726A9.248 9.248 0 0 1 1.714 11Z" />,
    <path d="M23.749 3.139a.846.846 0 0 0 .251-.6.838.838 0 0 0-.251-.598.859.859 0 0 0-.607-.248.868.868 0 0 0-.607.248L11.142 13.19 6.607 8.71A.86.86 0 0 0 6 8.462a.868.868 0 0 0-.607.248.846.846 0 0 0-.251.6.838.838 0 0 0 .25.598l5.144 5.077a.859.859 0 0 0 .935.184.859.859 0 0 0 .278-.184l12-11.846Z" />,
  ],
});

export default CircleWithRight;
