import { createIcon } from '@chakra-ui/react';

const Lock = createIcon({
  displayName: 'LockIcon',
  viewBox: '0 0 16 16',
  path: [
    <path d="M10.4 8.5v1.608c0 .975-.41 1.91-1.142 2.6A4.024 4.024 0 0 1 6.5 13.783a4.024 4.024 0 0 1-2.758-1.077A3.572 3.572 0 0 1 2.6 10.108V8.5H1.381V4.824C1.381 2.164 3.677 0 6.5 0s5.119 2.164 5.119 4.824V8.5H10.4Zm-6.825 1.608c0 .731.308 1.432.857 1.95a3.018 3.018 0 0 0 2.068.807c.776 0 1.52-.29 2.068-.808a2.679 2.679 0 0 0 .857-1.949V8.5h-5.85v1.608Zm7.069-5.284C10.644 2.671 8.784.92 6.5.92c-2.285 0-4.144 1.752-4.144 3.905v2.757h8.288V4.824Z" />,
    <path d="M6.431 9.342a1.347 1.347 0 0 0-.762.238 1.09 1.09 0 0 0-.435.603.98.98 0 0 0 .078.71c.116.223.312.406.558.518v1.3H6.9v-1.259c.266-.096.485-.27.622-.494a.985.985 0 0 0 .125-.73 1.08 1.08 0 0 0-.429-.635c-.221-.162-.5-.25-.787-.25Z" />,
    <ellipse cx="6.5" cy="10.874" rx="6.5" ry="6.126" />,
    <ellipse cx="6.5" cy="10.874" rx="4.875" ry="4.595" />,
    <ellipse cx="6.5" cy="10.874" rx="4.55" ry="4.288" />,
  ],
});

export default Lock;
