import { Flex } from '@chakra-ui/react';

export default function PageTitleBar({ children }: { children: React.ReactNode }) {
  return (
    <Flex
      align="center"
      bgColor="white"
      borderBottomColor="border_color"
      borderBottomWidth="1px"
      direction="row"
      justifyContent="space-between"
      px={6}
      py={3}
    >
      {children}
    </Flex>
  );
}
