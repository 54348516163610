import { createIcon } from '@chakra-ui/react';

const GeoFabric = createIcon({
  displayName: 'GeoFabricIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      clipRule="evenodd"
      d="M11.89 1.112a1.124 1.124 0 0 1 1.005 0l10.276 5.137a1.124 1.124 0 0 1 0 2.011l-10.276 5.138a1.124 1.124 0 0 1-1.005 0L1.614 8.26a1.124 1.124 0 0 1 0-2.01L11.89 1.111ZM4.63 7.255l7.763 3.881 7.762-3.881-7.762-3.882L4.63 7.255Z"
      fillRule="evenodd"
    />,
    <path
      clipRule="evenodd"
      d="M1.112 17.028c.277-.555.952-.78 1.507-.503l9.774 4.887 9.773-4.887a1.124 1.124 0 1 1 1.005 2.01l-10.276 5.139a1.124 1.124 0 0 1-1.005 0L1.614 18.536a1.124 1.124 0 0 1-.502-1.508Z"
      fillRule="evenodd"
    />,
    <path d="M2.62 11.387a1.124 1.124 0 0 0-1.006 2.01l10.276 5.139c.316.158.689.158 1.005 0l10.276-5.138a1.124 1.124 0 0 0-1.005-2.01l-9.773 4.886-9.774-4.887Z" />,
  ],
});

export default GeoFabric;
