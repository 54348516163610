import {
  Collection,
  ImportData,
  DocumentQueryCursor,
  MoveDocument,
  CollectionProperties,
  CreateDocumentEdgeCollectionForm,
  CreateKVCollectionForm,
  EdgeValues,
  CreateKVPairForm,
  IndexesValues,
  UpdateIndexJobRequest,
  DynamoTableRequest,
  CreateRedisCollectionForm,
  CreateDocumentCollectionForm,
} from '../types/collections';
import { ApiCallMethods, Headers } from '../constants/api';
import { ApiResponse } from '../types/commonTypes';
import { callApi } from '../utils/api';
import { getBaseURL } from '../utils/federation';
import { getFabric, getSession } from '../utils/persist';

export const getCollections = (): Promise<ApiResponse<Collection>> => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/collection`;
  return callApi(ApiCallMethods.Get, url);
};

export const getDocumentRecords = (jobId: number) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/job/${jobId}`;
  return callApi(ApiCallMethods.Put, url);
};

export const createDocumentCollection = (_key: string, collection: string) =>
  callApi(
    ApiCallMethods.Post,
    `${getBaseURL()}/_fabric/${getFabric()}/_api/document`,
    _key === '' ? {} : { _key },
    {
      headers: {
        Authorization: `bearer ${getSession().token}`,
      },
      params: { collection },
    },
  );

export const importDocuments = (data: ImportData, collectionName: string) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/import/${collectionName}`;
  return callApi(ApiCallMethods.Post, url, data);
};

export const exportDocument = (query: string) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/export`;
  return callApi(ApiCallMethods.Post, url, { query });
};

export const getJobId = (data: DocumentQueryCursor) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/cursor`;
  return callApi(ApiCallMethods.Post, url, data, {
    headers: {
      Authorization: `bearer ${getSession().token}`,
      'x-c8-async': Headers.XC8Async,
    },
  });
};

export const getMetaData = (collectionName: string) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/collection/${collectionName}`;
  return callApi(ApiCallMethods.Get, url);
};

export const getDocumentRecordsCount = (collectionName: string) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/collection/${collectionName}/count`;
  return callApi(ApiCallMethods.Get, url);
};

export const moveDocumentsCollection = (data: MoveDocument) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/cursor`;
  return callApi(ApiCallMethods.Post, url, data);
};

export const deleteDocumentCollection = (collection: string, keys: readonly string[]) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/simple/remove-by-keys`;
  return callApi(ApiCallMethods.Put, url, { collection, keys });
};

export const updateCollectionProperties = (collectionName: string, data: CollectionProperties) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/collection/${collectionName}/properties`;
  return callApi(ApiCallMethods.Put, url, data);
};

export const getPermissions = (collectionName: string) => {
  const url = `${getBaseURL()}/_api/user/${
    getSession().username
  }/database/${getFabric()}/collection/${collectionName}`;
  return callApi(ApiCallMethods.Get, url);
};

export const getDocumentData = (collection: string, keys: string[]) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/simple/lookup-by-keys`;
  return callApi(ApiCallMethods.Put, url, { collection, keys });
};

export const updateDocumentCode = (
  data: Record<string, any>,
  collectionName: string,
  docName: string,
  returnNew: boolean,
) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/document/${collectionName}/${docName}?returnNew=${returnNew}`;
  return callApi(ApiCallMethods.Put, url, data);
};

export const getDocument = (
  collectionName: string,
  keyName: string,
): Promise<{ data: { value: string } }> => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/document/${collectionName}/${keyName}`;
  return callApi(ApiCallMethods.Get, url);
};

export const updateDocumentByKey = (
  collectionName: string,
  keyName: string,
  data: { value: boolean },
) => {
  const queryString =
    'keepNull=false&mergeObjects=true&ignoreRevs=true&returnOld=true&returnNew=true&silent=false';
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/document/${collectionName}/${keyName}?${queryString}`;
  return callApi(ApiCallMethods.Patch, url, data);
};

export const updateDocument = (collectionName: string, data: object) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/document/${collectionName}?returnNew=true`;
  return callApi(ApiCallMethods.Put, url, data);
};

export const createDocumentEdgeCollection = (
  data: CreateDocumentEdgeCollectionForm | CreateRedisCollectionForm | CreateDocumentCollectionForm,
) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/collection`;
  return callApi(ApiCallMethods.Post, url, data);
};

export const createKVCollection = (data: CreateKVCollectionForm) => {
  const { name, expiration, group } = data;
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/kv/${name}?expiration=${expiration}&group=${group}`;
  return callApi(ApiCallMethods.Post, url, data);
};

export const createEdgeDocument = (data: EdgeValues, collection: string) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/document`;
  return callApi(ApiCallMethods.Post, url, data, { params: { collection } });
};

export const getKVCollectionsRecordsCount = (collectionName: string) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/kv/${collectionName}/count`;
  return callApi(ApiCallMethods.Get, url);
};

export const getKVCollectionData = (name: string, data: { offset: number; limit: number }) => {
  const { offset, limit } = data;
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/kv/${name}/values`;
  return callApi(ApiCallMethods.Post, url, data, {
    params: {
      offset,
      limit,
    },
  });
};

export const getKVCollections = () => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/kv`;
  return callApi(ApiCallMethods.Get, url);
};

export const createKVPair = (collectionName: string, data: CreateKVPairForm) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/kv/${collectionName}/value`;
  return callApi(ApiCallMethods.Put, url, data);
};

export const deleteKVPair = (collectionName: string, key: string) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/kv/${collectionName}/value/${key}`;
  return callApi(ApiCallMethods.Delete, url);
};

/**
 * After we are done with the truncation, we flush the WAL to remove all operations
 */
export const flushWal = () => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_admin/wal/flush?waitForSync=true&waitForCollector=true`;
  return callApi(ApiCallMethods.Put, url);
};

export const truncateCollection = (collectionName: string) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/collection/${collectionName}/truncate`;
  return callApi(ApiCallMethods.Put, url).then(flushWal);
};

export const reloadCollection = (collectionName: string) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/collection/${collectionName}/reload`;
  return callApi(ApiCallMethods.Put, url);
};

export const deleteCollection = (collectionName: string) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/collection/${collectionName}`;
  return callApi(ApiCallMethods.Delete, url);
};

export const getCollectionIndexes = (collection: string) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/index/?collection=${collection}`;
  return callApi(ApiCallMethods.Get, url);
};

export const getCreateIndexJobId = (data: IndexesValues, collectionName: string) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/index?collection=${collectionName}`;
  return callApi(ApiCallMethods.Post, url, data, {
    headers: {
      Authorization: `bearer ${getSession().token}`,
      'x-c8-async': Headers.XC8Async,
    },
  });
};

export const updateIndexJob = (data: UpdateIndexJobRequest) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_admin/frontend/job`;
  return callApi(ApiCallMethods.Post, url, data);
};

export const deleteIndex = (collectionName: string, indexName: string) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/index/${collectionName}/${indexName}`;
  return callApi(ApiCallMethods.Delete, url);
};

export const getIndexesJobs = () => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_admin/frontend/job`;
  return callApi(ApiCallMethods.Get, url);
};

export const analyseIndexJob = (jobId: string) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/job/${jobId}`;
  return callApi(ApiCallMethods.Put, url);
};

export const deleteFrontendJob = (jobId: string) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_admin/frontend/job/${jobId}`;
  return callApi(ApiCallMethods.Delete, url);
};

export const commonDynamoAPI = (data: DynamoTableRequest, action: string) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/dynamo`;
  return callApi(ApiCallMethods.Post, url, data, {
    headers: {
      Authorization: `bearer ${getSession().token}`,
      'X-Amz-Target': action,
    },
  });
};

export const getCollectionFigures = (collection: string) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/collection/${collection}/figures`;
  return callApi(ApiCallMethods.Get, url);
};

export const getCollectionProperties = (collection: string) => {
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/collection/${collection}/properties`;
  return callApi(ApiCallMethods.Get, url);
};

export const truncateKVCollection = (collectionName: string, groupID?: string) => {
  const params = groupID
    ? {
        params: {
          groupID,
        },
      }
    : {};
  const url = `${getBaseURL()}/_fabric/${getFabric()}/_api/kv/${collectionName}/truncate`;
  return callApi(ApiCallMethods.Put, url, {}, { ...params }).then(flushWal);
};
