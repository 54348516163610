import { useCallback, useMemo } from 'react';
import { Option } from '../../types/graph';
import { StreamStats, StreamStatsProps } from '../../types/stream';
import Figures from '../Figures';

function StreamStatistic({ streamStatsData }: StreamStatsProps) {
  const getStats = useCallback(() => {
    if (streamStatsData) {
      const {
        msgRateIn,
        msgRateOut,
        msgThroughputIn,
        msgThroughputOut,
        averageMsgSize,
        storageSize,
      }: StreamStats = JSON.parse(streamStatsData);

      return {
        messageRateIn: `${msgRateIn.toFixed(2)} KB`,
        messageRateOut: `${msgRateOut.toFixed(2)} KB`,
        messageThroughputIn: `${(msgThroughputIn / 1024).toFixed(2)} KB/sec`,
        messageThroughputOut: `${(msgThroughputOut / 1024).toFixed(2)} KB/sec`,
        averageMessageSize: `${(averageMsgSize / 1024).toFixed(2)} KB`,
        storage: `${(storageSize / 1024).toFixed(2)} KB`,
      };
    }

    return {
      messageRateIn: `0 messages/sec`,
      messageRateOut: `0 messages/sec`,
      messageThroughputIn: `0 KB/sec`,
      messageThroughputOut: `0 KB/sec`,
      averageMessageSize: `0 KB`,
      storage: `0 KB`,
    };
  }, [streamStatsData]);

  const streamStats: { [key: string]: string } = useMemo(() => getStats(), [getStats]);
  const {
    messageRateIn,
    messageRateOut,
    messageThroughputIn,
    messageThroughputOut,
    averageMessageSize,
    storage,
  } = streamStats;

  const statsToShow: Option[] = [
    { label: 'MESSAGE RATE IN', value: messageRateIn },
    { label: 'MESSAGE RATE OUT', value: messageRateOut },
    { label: 'MESSAGE THROUGHPUT IN', value: messageThroughputIn },
    { label: 'MESSAGE THROUGHPUT OUT', value: messageThroughputOut },
    { label: 'AVERAGE MESSAGE SIZE', value: averageMessageSize },
    { label: 'STORAGE', value: storage },
  ];

  return <Figures figures={statsToShow} />;
}

export default StreamStatistic;
