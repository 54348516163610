const baseStyle = {
  fontFamily: 'heading',
  fontWeight: 'bold',
  my: 0,
};

const defaultProps = {
  size: 'xl',
};

const sizes = {
  '4xl': {
    fontSize: ['4xl', null, '5xl'],
    letterSpacing: 'tighter',
    lineHeight: ['4xl', null, '5xl'],
  },
  '3xl': {
    fontSize: ['3xl', null, '4xl'],
    letterSpacing: 'tighter',
    lineHeight: ['3xl', null, '4xl'],
  },
  '2xl': {
    fontSize: ['2xl', null, '3xl'],
    letterSpacing: 'tight',
    lineHeight: ['2xl', null, '3xl'],
  },
  xl: {
    fontSize: ['xl', null, '2xl'],
    letterSpacing: [null, null, 'tight'],
    lineHeight: ['xl', null, '2xl'],
  },
  lg: {
    fontSize: ['lg', null, 'xl'],
    lineHeight: ['md', null, 'lg'],
  },
  md: {
    fontSize: ['base', null, 'lg'],
    lineHeight: ['md', null, 'md'],
  },
  sm: {
    fontSize: ['md', null, 'base'],
    lineHeight: ['md', null, 'md'],
  },
  xs: {
    fontSize: ['sm', null, 'md'],
    lineHeight: ['sm', null, 'md'],
  },
};

const variants = {
  appHeading: {
    fontWeight: 'semibold',
    lineHeight: 'xl',
    size: 'xl',
  },
};

export default {
  baseStyle,
  defaultProps,
  sizes,
  variants,
};
