import { createIcon } from '@chakra-ui/react';

const ChevronRight = createIcon({
  displayName: 'ChevronRightIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      clipRule="evenodd"
      d="M8.65227 4.21478C8.13971 4.72734 8.13971 5.55837 8.65227 6.07093L14.5813 12L8.65227 17.9291C8.13971 18.4416 8.13971 19.2727 8.65227 19.7852C9.16484 20.2978 9.99587 20.2978 10.5084 19.7852L17.3656 12.9281C17.8781 12.4155 17.8781 11.5845 17.3656 11.0719L10.5084 4.21478C9.99587 3.70221 9.16484 3.70221 8.65227 4.21478Z"
      fillRule="evenodd"
    />
  ),
});

export default ChevronRight;
